import { isValid } from 'date-fns';
import { action, makeAutoObservable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { formatQueryParamsDate } from 'src/helpers/functions';
import {
  GetGuaranteeFilterFiltersType,
  guaranteeService
} from 'src/services/guarantee';
import { GlobalStore } from '../global';
import { LayoutStore } from '../layout';
import { MerchantStore } from '../merchant';
import { AcquirersType, GuaranteesFiltersType, GuaranteesType } from './types';

export default class GuaranteesStore {
  layoutStore: LayoutStore;
  merchantStore: MerchantStore;

  guarantees: GuaranteesType;
  acquirers: AcquirersType;

  filters: GuaranteesFiltersType;

  constitutionsOptions: AutoCompleteOptionsType;

  queryEnabled: boolean;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.layoutStore = globalStore.LayoutStore;
    this.merchantStore = globalStore.MerchantStore;

    this.guarantees = [];
    this.acquirers = [];

    const today = new Date();
    this.filters = {
      fromLiquidationDate: today,
      toLiquidationDate: today,
      constituted: null,
      acquirer: null
    };

    this.constitutionsOptions = [
      { id: 'constituted', label: 'Constituída' },
      { id: 'notConstituted', label: 'Não Constituída' },
      { id: 'notReached', label: 'Não Atingida' }
    ];

    this.queryEnabled = true;
  }

  @action.bound
  setFilters = (filters: GuaranteesFiltersType) => {
    this.filters = filters;
    this.queryEnabled =
      isValid(this.filters.fromLiquidationDate) &&
      isValid(this.filters.toLiquidationDate);
  };

  //#region Guarantees

  @action.bound
  handleGuarantees = async () => {
    try {
      this.layoutStore.showBackdrop();

      const filters: GetGuaranteeFilterFiltersType = {
        fromDate: formatQueryParamsDate(this.filters.fromLiquidationDate),
        toDate: formatQueryParamsDate(this.filters.toLiquidationDate),
        ...(this.filters?.acquirer?.id && {
          acquirer: this.filters.acquirer.id
        }),
        ...(this.merchantStore?.selectedMerchant?.id && {
          merchantId: this.merchantStore.selectedMerchant.id
        }),
        ...(this.filters?.constituted?.id && {
          constitution: this.filters.constituted.id
        })
      };

      const response = await guaranteeService.getGuaranteeFilter(
        filters,
        this.merchantStore.merchants
      );

      this.setGuarantees(response);
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível recuperar as garantias. Por favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
      this.setGuarantees([]);
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  setGuarantees = (guarantees: GuaranteesType) => {
    this.guarantees = guarantees;
  };
  //#endregion

  //#region Acquirers
  @action.bound
  handleAcquirers = async () => {
    try {
      this.layoutStore.showBackdrop();

      const response = await guaranteeService.getGuaranteeAcquirers();

      this.setAcquirers(response);
    } catch (e) {
      enqueueSnackbar(
        'Não foi possível carregar os adquirentes. Por favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
      this.setAcquirers([]);
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  setAcquirers = (acquirers: AcquirersType) => {
    this.acquirers = acquirers;
  };
  //#endregion
}
