import {
  NavigateBeforeTwoTone,
  NavigateNextTwoTone
} from '@mui/icons-material';
import { Box, Container, Typography, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { StyledTextProps, StyledWrapperProps } from './types';

const Wrapper = styled(({ vPosition, ...props }: StyledWrapperProps) => (
  <Box {...props} />
))(({ vPosition }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...(vPosition === 'center' && {
    alignItems: 'center'
  }),
  ...(vPosition === 'left' && {
    alignItems: 'flex-start'
  }),
  ...(vPosition === 'right' && {
    alignItems: 'flex-end'
  })
}));

export const Styled = {
  Container: styled(Container)({
    minHeight: '100%'
  }),

  Content: styled((props: StyledWrapperProps) => <Wrapper {...props} />)(
    ({ theme: { spacing } }) => ({
      gap: spacing(3)
    })
  ),

  Head: styled((props: StyledWrapperProps) => <Wrapper {...props} />)(
    ({ theme: { spacing } }) => ({
      padding: `${spacing(4)} 0`,
      gap: spacing(0.5)
    })
  ),

  Text: styled(({ vPosition, ...props }: StyledTextProps) => (
    <Typography {...props} />
  ))(({ vPosition }) => ({
    ...(vPosition && { textAlign: vPosition })
  })),

  Link: styled(NavLink)(({ theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    gap: spacing(1)
  })),

  BreadcrumbsText: styled(Typography)(
    ({
      theme: {
        typography: { pxToRem },
        palette: {
          secondary: { light }
        }
      }
    }) => ({
      fontSize: pxToRem(13),
      color: light
    })
  ),

  BreadcrumbsSeparator: styled(NavigateNextTwoTone)(
    ({
      theme: {
        typography: { pxToRem }
      }
    }) => ({
      fontSize: pxToRem(14)
    })
  ),

  BackIcon: styled(NavigateBeforeTwoTone)(
    ({
      theme: {
        palette: {
          secondary: { light }
        },
        typography: { pxToRem }
      }
    }) => ({
      fontSize: pxToRem(14),
      color: light
    })
  )
};
