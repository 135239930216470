import { OptionsObject, SnackbarMessage, enqueueSnackbar } from 'notistack';
import env from 'src/env';
import { getToken } from 'src/helpers/cookies/auth_user';
import { navigateToLogin } from 'src/helpers/navigationRoutes';

type RequestType = {
  url: RequestInfo | URL;
  method: 'GET' | 'POST' | 'PUT';
  isAuthenticated: boolean;
  baseUrl?: string;
  body?: Object;
  errorMessage?: SnackbarMessage;
  errorMessageOptions?: OptionsObject<'error'>;
  headers?: HeadersInit;
  mode?: RequestMode;
  options?: RequestInit;
  successMessage?: SnackbarMessage;
  successMessageOptions?: OptionsObject<'success'>;
};

type ResponseDataType<T> = T & {
  expose?: boolean;
  statusCode?: number;
  message?: string;
};

type ResponseType<T> = {
  body: ReadableStream<Uint8Array> | null;
  bodyUsed: boolean;
  headers: Headers;
  ok: boolean;
  redirected: boolean;
  status: number;
  statusText: string;
  type: globalThis.ResponseType;
  url: string;
  data: ResponseDataType<T>;
};

const requester = async <T>({
  baseUrl = env.BASE_URL,
  body,
  errorMessage,
  errorMessageOptions,
  headers,
  isAuthenticated,
  method,
  mode = 'cors',
  url,
  options,
  successMessage,
  successMessageOptions
}: RequestType): Promise<ResponseType<T>> => {
  const token = getToken();

  if (isAuthenticated && !token) {
    enqueueSnackbar('Sua sessão expirou. Por favor, faça login novamente.', {
      variant: 'error'
    });
    navigateToLogin();
    throw new Error(
      'Unauthenticated user. The zak_register_auth_token cookie was not found.'
    );
  }

  try {
    const response = await fetch(`${baseUrl}${url}`, {
      mode: options?.mode ?? mode,
      method: options?.method ?? method,
      headers: {
        ...options?.headers,
        ...headers,
        'Content-Type': 'application/json',
        ...(isAuthenticated && {
          Authorization: `Bearer ${token}`
        })
      },
      body: options?.body ?? (body ? JSON.stringify(body) : undefined)
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data: ResponseDataType<T> = await response.json();

    if (successMessage) {
      enqueueSnackbar(successMessage, {
        ...successMessageOptions,
        variant: successMessageOptions?.variant ?? 'success'
      });
    }

    return {
      body: response.body,
      bodyUsed: response.bodyUsed,
      headers: response.headers,
      ok: response.ok,
      redirected: response.redirected,
      status: response.status,
      statusText: response.statusText,
      type: response.type,
      url: response.url,
      data: data
    };
  } catch (e) {
    const error = e as Error;

    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        ...errorMessageOptions,
        variant: errorMessageOptions?.variant ?? 'error'
      });
    }

    throw new Error(error?.message);
  }
};

export default requester;
