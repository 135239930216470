import requester from '../requester';
import {
  PostMerchantBodyType,
  PostMerchantResponseType,
  PostMerchantUpdateOptInBodyType
} from './types';

const url = 'merchant';
const isAuthenticated = true;

export const merchantService = {
  postMerchant: async (body: PostMerchantBodyType) => {
    try {
      const {
        status,
        data: { merchant }
      } = await requester<PostMerchantResponseType>({
        isAuthenticated,
        url,
        method: 'POST',
        body
      });

      if (status !== 200 || !merchant) {
        throw new Error(
          ` Status: ${status}. Error when creating commercial establishment.`
        );
      }

      return merchant;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  },
  postMerchantUpdateOptIn: (body: PostMerchantUpdateOptInBodyType) =>
    requester({
      url: `${url}/update-optin`,
      isAuthenticated,
      body,
      method: 'POST'
    })
};
