import { format } from 'date-fns';
import {
  formatDateToStartOfDay,
  getMerchantPropById,
  handleQueryParams
} from 'src/helpers/functions';
import { formatAcquirerSubacquirer, formatCurrency } from 'src/helpers/masks';
import { MerchantItemType } from 'src/stores/merchant';
import requester from '../requester';
import {
  GetGuaranteeAcquirersDtoResponseType,
  GetGuaranteeAcquirersResponseType,
  GetGuaranteeFilterDtoResponseType,
  GetGuaranteeFilterFiltersType,
  GetGuaranteeFilterResponseType
} from './types';

const url = 'guarantee';
const isAuthenticated = true;

export const guaranteeService = {
  getGuaranteeFilter: async (
    filters: GetGuaranteeFilterFiltersType,
    merchants: MerchantItemType[]
  ): Promise<GetGuaranteeFilterResponseType> => {
    try {
      const queryParams = handleQueryParams(filters);

      const {
        data: { statusCode, message, guarantees }
      } = await requester<GetGuaranteeFilterDtoResponseType>({
        url: `${url}/filter${queryParams}`,
        method: 'GET',
        isAuthenticated
      });

      if (statusCode && statusCode > 299) {
        throw new Error(message);
      }

      return guarantees.map(
        ({
          chargeId,
          liquidationDate,
          oneratedAmount,
          merchantId,
          acquirerSubacquirer,
          paymentsArrangement,
          agency,
          id: guaranteeId,
          account,
          constitution
        }) => {
          const currentLiqDate = formatDateToStartOfDay(liquidationDate);

          return {
            id: guaranteeId,
            chargeId,
            liquidationDate: currentLiqDate,
            oneratedAmount,
            merchant: getMerchantPropById(merchants, merchantId, 'name') ?? '',
            merchantCnpj:
              getMerchantPropById(merchants, merchantId, 'cnpj') ?? '',
            acquirerSubacquirer: formatAcquirerSubacquirer(acquirerSubacquirer),
            paymentsArrangement,
            agency,
            account,
            constitution,
            formattedLiquidationDate: format(currentLiqDate, 'dd/MM/yyyy'),
            formattedOneratedAmount: formatCurrency(oneratedAmount, {
              removeSuffix: true,
              inCents: true
            })
          };
        }
      );
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  },
  getGuaranteeAcquirers:
    async (): Promise<GetGuaranteeAcquirersResponseType> => {
      try {
        const response = await requester<GetGuaranteeAcquirersDtoResponseType>({
          url: `${url}/acquirers`,
          method: 'GET',
          isAuthenticated
        });

        const {
          data: { message, acquirers, statusCode }
        } = response;

        if (statusCode && statusCode > 299) {
          throw new Error(message);
        }

        return acquirers
          .filter((item) => !!item)
          .map((document: string) => ({
            document,
            name: formatAcquirerSubacquirer(document)
          }));
      } catch (e) {
        const error = e as Error;

        console.error(error);
        throw new Error(error?.message);
      }
    }
};
