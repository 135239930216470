import { Box, Button, alpha, lighten, styled } from '@mui/material';
import { ButtonIcon } from 'src/components/buttonIcon';

export const Styled = {
  Container: styled('header')(
    ({
      theme: {
        header: { height, background },
        spacing,
        palette,
        breakpoints,
        sidebar
      }
    }) => ({
      height: height,
      padding: spacing(0, 2),
      position: 'fixed',
      right: 0,
      zIndex: 6,
      backgroundColor: alpha(background, 0.95),
      justifyContent: 'space-between',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      boxShadow:
        palette.mode === 'dark'
          ? `0 1px 0 ${alpha(
              lighten(palette.primary.main, 0.7),
              0.15
            )}, 0px 2px 8px -3px ${
              palette.alpha.black[20]
            }, 0px 5px 22px -4px ${palette.alpha.black[10]};`
          : `0px 2px 8px -3px ${alpha(
              palette.secondary.main,
              0.2
            )}, 0px 5px 22px -4px ${alpha(palette.common.black, 0.1)};`,

      [`${breakpoints.up('lg')}`]: {
        left: sidebar.width,
        width: 'auto',
        justifyContent: 'flex-end'
      }
    })
  ),

  ButtonMenu: styled(ButtonIcon)(({ theme: { spacing, breakpoints } }) => ({
    marginRight: spacing(2),

    [`${breakpoints.up('lg')}`]: {
      display: 'none'
    }
  })),

  UserCardButton: styled(Button)(({ theme: { spacing } }) => {
    const space = spacing(1);

    return {
      paddingLeft: space,
      paddingRight: space,
      gap: space
    };
  }),

  PopoverUserCard: styled(Box)(({ theme: { palette, spacing } }) => ({
    background: palette.alpha.secondary[5],
    padding: spacing(2),
    minWidth: '210px',
    display: 'flex',
    gap: spacing(1)
  })),

  ButtonExit: styled(Button)(({ theme: { spacing } }) => {
    const space = spacing(1);

    return {
      margin: space,
      width: 'fill-available',
      gap: space
    };
  })
};
