import { Button, List } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { PermissionTooltip } from 'src/components/permissionTooltip';
import { withStores } from 'src/stores';
import { Styled } from './styles';
import { MenuItemType, MenuProps } from './types';

export const Menu = withStores(
  ['LayoutStore', 'RouterStore'],
  ({ LayoutStore, RouterStore }: Required<MenuProps>) => {
    const { closeSidebar } = LayoutStore;
    const { privateRoutes, modules } = RouterStore;

    const includedModules: string[] = [];
    const menuRoutes = privateRoutes.filter((item) => item.isMenu);

    const menu: MenuItemType[] = menuRoutes
      .map((route) => {
        if (includedModules.find((module) => module === route.module)) {
          return null;
        }

        if (route.module) {
          const moduleRoutes = menuRoutes.filter(
            (item) => item.module === route.module
          );

          includedModules.push(route.module);

          return {
            title: modules[route.module].name ?? '',
            routes: moduleRoutes
          };
        }

        return { title: route.title ?? '', routes: [route] };
      })
      .filter((item): item is MenuItemType => item !== null);

    return (
      <>
        <Styled.LogoZak variant="white" />
        <Styled.Line />
        <Styled.Container component="nav">
          {menu.map(({ title, routes }, index) => {
            const multipleMenuItems = routes.length > 1;
            const disabled =
              multipleMenuItems &&
              routes.every((item) => item.hasViewPermission === false);

            return (
              <List
                key={index}
                component="div"
                subheader={
                  title && multipleMenuItems ? (
                    <Styled.ListSubheader
                      component="div"
                      disabled={disabled}
                      disableSticky
                    >
                      {title}
                    </Styled.ListSubheader>
                  ) : undefined
                }
              >
                <Styled.SubMenuContainer multipleMenuItems={multipleMenuItems}>
                  {routes
                    .filter(({ isMenu }) => isMenu)
                    .map(({ hasViewPermission, icon, path, title }, index) => {
                      const disabled = !hasViewPermission;

                      return (
                        <Styled.SubMenuItem key={index}>
                          <PermissionTooltip
                            disabled={disabled}
                            variant="light"
                            title="Você não tem permissão para acessar essa página"
                          >
                            <Button
                              disableRipple
                              component={NavLink}
                              disabled={disabled}
                              onClick={closeSidebar}
                              to={`/${path}`}
                              startIcon={icon}
                            >
                              {title}
                            </Button>
                          </PermissionTooltip>
                        </Styled.SubMenuItem>
                      );
                    })}
                </Styled.SubMenuContainer>
              </List>
            );
          })}
        </Styled.Container>
      </>
    );
  }
);
