import { Box, Typography, lighten, styled } from '@mui/material';

export const Styled = {
  Content: styled(Box)(({ theme: { spacing } }) => ({
    textAlign: 'left',
    maxWidth: spacing(13)
  })),

  Title: styled(Typography)(({ theme: { typography, palette } }) => ({
    fontWeight: typography.fontWeightBold,
    color: palette.secondary.main,
    display: 'block',
    textTransform: 'capitalize'
  })),

  Description: styled(Typography)(({ theme: { palette } }) => ({
    display: 'block',
    color: lighten(palette.secondary.main, 0.5)
  }))
};
