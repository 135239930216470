import requester from '../requester';
import {
  GetConfigReadZakScheduleRegisterResponseType,
  PutConfigReadZakScheduleRegisterBodyType
} from './types';

const url = 'config-read';
const config_read_zak_schedule_register_url = `${url}/zak/schedule-register`;
const isAuthenticated = true;

export const configReadService = {
  getConfigReadZakScheduleRegister: async () => {
    try {
      const response =
        await requester<GetConfigReadZakScheduleRegisterResponseType>({
          url: config_read_zak_schedule_register_url,
          isAuthenticated,
          method: 'GET'
        });

      const { data } = response;
      const { statusCode, message } = data;

      if (statusCode && statusCode > 299) {
        throw new Error(message);
      }

      return response;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  },
  putConfigReadZakScheduleRegister: (
    body: PutConfigReadZakScheduleRegisterBodyType
  ) =>
    requester({
      url: config_read_zak_schedule_register_url,
      method: 'PUT',
      isAuthenticated,
      body
    })
};
