import { formatCNPJ } from 'src/helpers/masks';
import requester from '../requester';
import {
  GetMerchantDashListDtoResponseType,
  GetMerchantDashListResponseType
} from './types';

const url = 'merchant-dash';
const isAuthenticated = true;

export const merchantDashService = {
  getMerchantDashList: async (): Promise<GetMerchantDashListResponseType> => {
    try {
      const {
        data: { merchants }
      } = await requester<GetMerchantDashListDtoResponseType>({
        isAuthenticated,
        url: `${url}/v2/list`,
        method: 'GET',
        errorMessage:
          'Não foi possível recuperar os estabelecimentos. Por favor, tente novamente mais tarde.'
      });

      const formatOptInStatus = (optInStatus: string) => {
        let status = optInStatus;

        switch (optInStatus?.toLowerCase()) {
          case 'optedout':
            status = 'Opted-Out';
            break;
          case 'optedin':
            status = 'Opted-In';
            break;
          case 'pending':
            status = 'Pendente';
            break;
        }

        return status;
      };

      let currentMerchants: GetMerchantDashListResponseType = merchants.map(
        ({
          legalName,
          id,
          cnpj,
          optInStatus,
          createdAt,
          paymentsArrangements
        }) => {
          return {
            id,
            cnpj,
            name: legalName,
            optInStatus,
            optedIn: optInStatus === 'optedIn',
            createdAt,
            paymentArrangements: [...paymentsArrangements, '99T', '99C', '99D'],
            formattedCnpj: formatCNPJ(cnpj),
            formattedOptInStatus: formatOptInStatus(optInStatus)
          };
        }
      );

      currentMerchants = currentMerchants.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      currentMerchants = currentMerchants.filter(
        ({ createdAt }) =>
          new Date(createdAt) > new Date('2023-07-19T00:28:29.032Z')
      );

      return currentMerchants;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  }
};
