import { handleQueryParams } from 'src/helpers/functions';
import requester from '../requester';
import { GetScheduleDtoResponseType, GetScheduleFiltersType } from './types';

const url = 'schedule';
const isAuthenticated = true;

export const scheduleService = {
  getSchedule: async (filters: GetScheduleFiltersType) => {
    try {
      const queryParams = handleQueryParams(filters);

      const response = await requester<GetScheduleDtoResponseType>({
        url: `${url}${queryParams}`,
        method: 'GET',
        isAuthenticated
      });

      const { status, data } = response;
      const { schedule } = data;

      if (status > 299) {
        throw new Error(
          `Unable to find schedule for this establishment. Status: ${status}. Schedule: ${schedule}`
        );
      }

      return response;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  }
};
