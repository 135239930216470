import { Avatar } from '@mui/material';
import { withStores } from 'src/stores';
import { Styled } from './styles';
import { UserCardProps } from './types';

export const UserCard = withStores(
  ['UserStore'],
  ({ UserStore }: Required<UserCardProps>) => {
    const login = UserStore.userSession?.login ?? '';

    return (
      <>
        <Avatar variant="rounded" alt={login} src="/images/avatar.png" />
        <Styled.Content>
          <Styled.Title noWrap variant="body1">
            {login}
          </Styled.Title>
          {UserStore.userSession?.formattedRoles && (
            <Styled.Description noWrap variant="body2">
              {UserStore.userSession.formattedRoles}
            </Styled.Description>
          )}
        </Styled.Content>
      </>
    );
  }
);
