import requester from '../requester';
import { GetContractByIdResponseType, PutContractBodyType } from './types';

const url = 'contract';
const isAuthenticated = true;

export const contractService = {
  getContractById: async (billingId: string) => {
    try {
      const response = await requester<GetContractByIdResponseType>({
        url: `${url}/${billingId}`,
        isAuthenticated,
        method: 'GET'
      });

      // Mock
      // return { data: contract_mock };

      return response;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  },
  putContract: async (body: PutContractBodyType) => {
    try {
      const response = await requester({
        url,
        method: 'PUT',
        isAuthenticated,
        body
      });

      const { data } = response;
      const { statusCode, message } = data;

      if (statusCode && statusCode > 299) {
        throw new Error(message);
      }

      return response;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  }
};
