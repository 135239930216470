import { action, makeAutoObservable } from 'mobx';
import { GlobalStore } from '../global';

export default class SuppliersStore {
  selectedSupplierId: string;

  suppliers: SelectOptionsType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);

    this.suppliers = [
      { value: 'all', label: 'Todos' },
      {
        value: '4a66da48-87b5-402d-94b9-1cd86a1bb7e5',
        label: 'Frubana'
      }
    ];

    this.selectedSupplierId = this.suppliers[1].value;
  }

  @action.bound
  setSelectedSupplierId = (value: string) => {
    this.selectedSupplierId = value;
  };
}
