const env = {
  local: {
    BASE_URL: 'http://localhost:3001/'
  },
  development: {
    BASE_URL: 'https://zak-register-api.development.zak.app/'
  },
  production: {
    BASE_URL: 'https://zak-register-api.zak.app/'
  }
};

export default {
  ...env[process.env.REACT_APP_STAGE || 'local']
};
