import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBr from 'date-fns/locale/pt-BR';
import { Provider } from 'mobx-react';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { BackdropPage } from './components/backdropPage';
import { Router } from './components/router';
import { GlobalStore } from './stores/global';
import ThemeContainer from './theme';

const globalStore: GlobalStore = new GlobalStore();

const App = () => {
  useEffect(() => {
    globalStore.init();
  }, []);

  return (
    <Provider
      LayoutStore={globalStore.LayoutStore}
      UserStore={globalStore.UserStore}
      RouterStore={globalStore.RouterStore}
      MerchantStore={globalStore.MerchantStore}
      ScheduleStore={globalStore.ScheduleStore}
      ContractsStore={globalStore.ContractsStore}
      GuaranteesStore={globalStore.GuaranteesStore}
      ConfigStore={globalStore.ConfigStore}
      SuppliersStore={globalStore.SuppliersStore}
      OrdersStore={globalStore.OrdersStore}
      LimitStore={globalStore.LimitStore}
    >
      <HelmetProvider>
        <BrowserRouter>
          <ThemeContainer>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBr}
            >
              <SnackbarProvider maxSnack={5}>
                <CssBaseline />
                <BackdropPage />
                <Router />
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeContainer>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
