import { Box, styled } from '@mui/material';
import { StyledContainerProps } from './types';

export const Styled = {
  Container: styled(
    ({ isActivePrivateRoutes, ...props }: StyledContainerProps) => (
      <Box {...props} />
    )
  )(({ isActivePrivateRoutes, theme: { header, breakpoints, sidebar } }) => ({
    flex: '1',
    ...(isActivePrivateRoutes && {
      height: '100%',
      marginTop: header.height,

      [`${breakpoints.up('lg')}`]: {
        marginLeft: sidebar.width
      }
    })
  }))
};
