import { Box, styled } from '@mui/material';

export const Styled = {
  AnimatedContainer: styled(Box)(
    ({
      theme: {
        palette: { primary }
      }
    }) => {
      const position = 0;
      const height = '5px';

      return {
        position: 'fixed',
        left: position,
        top: position,
        width: '100%',
        height: height,

        '&:before': {
          top: position,
          bottom: position,
          height: height,
          content: '" "',
          position: 'absolute',
          left: '-50%',
          width: '50%',
          animationDuration: '2s',
          animationDelay: '600ms',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
          animationName: 'infinite-progress-bar',
          background: primary.main
        }
      };
    }
  )
};
