import { action, makeAutoObservable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { getMerchantById } from 'src/helpers/functions';
import { isEmptyString, validateOnlyNumber } from 'src/helpers/validators';
import { merchantService } from 'src/services/merchant';
import { merchantDashService } from 'src/services/merchantDash';
import { optService } from 'src/services/opt';
import { GlobalStore } from '../global';
import { LayoutStore } from '../layout';
import {
  CreateMerchantModalType,
  MerchantItemType,
  MerchantsType,
  OptModalType
} from './types';

export default class MerchantStore {
  layoutStore: LayoutStore;

  merchants: MerchantsType;
  merchantsLoading: boolean | null;

  availablePaymentArrangements: string[];
  selectedMerchant: MerchantItemType | null;

  optModal: OptModalType;
  createMerchantModalVisibility: boolean;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.layoutStore = globalStore.LayoutStore;

    this.merchants = [];
    this.merchantsLoading = null;

    this.selectedMerchant = null;
    this.availablePaymentArrangements = ['99D', '99C', '99T'];

    this.optModal = null;
    this.createMerchantModalVisibility = false;
  }

  @action.bound
  setMerchantsLoading = (value: boolean) => {
    this.merchantsLoading = value;
  };

  @action.bound
  handleMerchants = async () => {
    try {
      this.setMerchantsLoading(true);
      this.layoutStore.showBackdrop();

      const response = await merchantDashService.getMerchantDashList();

      this.setMerchants(response);
    } finally {
      this.setMerchantsLoading(false);
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  setMerchants = (merchants: MerchantsType) => {
    this.merchants = merchants;
  };

  @action.bound
  init = async () => {
    await this.handleMerchants();
  };

  @action.bound
  selectMerchant = (id: string) => {
    this.selectedMerchant = getMerchantById(this.merchants, id);

    if (this.selectedMerchant)
      this.availablePaymentArrangements =
        this.selectedMerchant.paymentArrangements;
    else {
      this.availablePaymentArrangements = ['99D', '99C', '99T'];
    }
  };

  @action.bound
  setCreateMerchantModalVisibility = (modal: boolean) => {
    this.createMerchantModalVisibility = modal;
  };

  @action.bound
  createMerchant = async (modal: CreateMerchantModalType) => {
    try {
      if (!modal) {
        throw new Error('Modal is undefined or null');
      }

      const { cnpj, legalName, merchantId, tradingName } = modal;
      const handleErrorMessage = (message: string) =>
        enqueueSnackbar(message, { variant: 'error' });

      if (isEmptyString(merchantId)) {
        handleErrorMessage('Informe um ID válido');
        return;
      }

      if (validateOnlyNumber(cnpj).length !== 14) {
        handleErrorMessage('Informe um CNPJ válido');
        return;
      }

      if (isEmptyString(legalName)) {
        handleErrorMessage('Informe uma Razão Social válida');
        return;
      }

      if (isEmptyString(tradingName)) {
        handleErrorMessage('Informe um Nome Fantasia válido');
        return;
      }

      this.layoutStore.showBackdrop();

      await merchantService.postMerchant({
        merchantId,
        cnpj,
        legalName,
        tradingName
      });

      await this.handleMerchants();
      enqueueSnackbar('Estabelecimento cadastrado com sucesso.', {
        variant: 'success'
      });

      this.setCreateMerchantModalVisibility(false);
    } catch (e) {
      console.error(e);

      enqueueSnackbar(
        'Não foi possível cadastrar o estabelecimento. Por favor, tente novamente mais tarde',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  handleOptInOut = async () => {
    try {
      this.layoutStore.showBackdrop();

      if (!this.optModal) {
        throw new Error('OptModal is undefined or null');
      }

      await optService.postOpt(
        this.optModal.operation === 'Opt-In' ? 'in' : 'out',
        { merchantId: this.optModal.merchant.id }
      );

      await this.handleMerchants();

      enqueueSnackbar(
        `${this.optModal.operation} do estabelecimento ${this.optModal.merchant.name} realizado com sucesso.`,
        {
          variant: 'success'
        }
      );

      this.setOptModal(null);
    } catch (e) {
      enqueueSnackbar(
        `Não foi possível fazer o ${this.optModal?.operation}. Por favor, tente novamente mais tarde.`,
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  setOptModal = (optModal: MerchantStore['optModal']) => {
    this.optModal = optModal;
  };
}
