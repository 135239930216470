import { formatUserRoles } from 'src/helpers/masks';
import requester from '../requester';
import {
  GetUserDtoResponseType,
  GetUserResponseType,
  PostUserBodyType,
  PostUserLoginBodyType,
  PostUserLoginResponseType,
  PostUserResponseType
} from './types';

const url = 'user';

export const userService = {
  postUserLogin: async (body: PostUserLoginBodyType) => {
    try {
      const response = await requester<PostUserLoginResponseType>({
        url: `${url}/login`,
        body,
        method: 'POST',
        isAuthenticated: false
      });

      const { data, status } = response;
      const { token } = data;

      if (status !== 200) {
        throw new Error('Unable to authenticate');
      }

      if (!token) {
        throw new Error('Unable to authenticate without token');
      }

      return data;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  },
  getUser: async (): Promise<GetUserResponseType> => {
    try {
      const { data, status } = await requester<GetUserDtoResponseType>({
        url: url,
        method: 'GET',
        isAuthenticated: true
      });

      if (status != 200) {
        throw new Error('Unable to retrieve user list');
      }

      const users: GetUserResponseType = data.users.map(
        ({ id, email, login, userData, roles }) => {
          const currentRoles = roles.map(({ name }) => name);

          return {
            id,
            email,
            login,
            name: userData?.fullName ?? '',
            roles: currentRoles,
            formattedRoles: formatUserRoles(currentRoles)
          };
        }
      );

      return users;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  },
  postUser: async (body: PostUserBodyType) => {
    try {
      const response = await requester<PostUserResponseType>({
        url,
        method: 'POST',
        isAuthenticated: true,
        body
      });

      if (response.status != 200 || !response.data.user) {
        throw new Error(
          `Unable to register the user. Status: ${response.status}`
        );
      }

      return response;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  }
};
