import { Badge, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const Styled = {
  Container: styled(Link)({
    display: 'block',
    width: '140px'
  }),

  Version: styled(Badge)(({ theme: { typography } }) => ({
    '.MuiBadge-badge': {
      right: '-2px',
      top: '20px',
      fontSize: typography.pxToRem(11)
    }
  })),

  Image: styled('img')(() => {
    const size = '100%';

    return {
      width: size,
      height: size,
      display: 'block'
    };
  })
};
