import { Box, styled } from '@mui/material';

export const Styled = {
  Container: styled(Box)(() => {
    const style = 'none';

    return {
      overflow: 'auto',
      scrollbarWidth: style /* Firefox */,
      msOverflowStyle: style /* Internet Explorer 10+ */,

      '&::-webkit-scrollbar': {
        display: style /* Safari and Chrome */
      }
    };
  })
};
