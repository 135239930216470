import requester from '../requester';
import { PostOptBodyType, PostOptResponseType } from './types';

const url = 'opt';
const isAuthenticated = true;

export const optService = {
  postOpt: async (operation: 'in' | 'out', body: PostOptBodyType) => {
    try {
      const response = await requester<PostOptResponseType>({
        isAuthenticated,
        url: `${url}-${operation}`,
        method: 'POST',
        body
      });

      const { status } = response;

      if (status !== 200) {
        return new Error(`Unable to opt-${operation}`);
      }

      return response;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  }
};
