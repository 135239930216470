import { Drawer, alpha, lighten, styled } from '@mui/material';

const Container = styled('aside')(
  ({
    theme: {
      sidebar: { width },
      palette
    }
  }) => ({
    width: width,
    minWidth: width,
    color: palette.alpha.white[70],
    position: 'relative',
    zIndex: 7,
    height: '100%'
  })
);

export const Styled = {
  FixedMenuContainer: styled(Container)(
    ({
      theme: {
        palette: { mode },
        header: { background, darkBackground },
        breakpoints,
        sidebar
      }
    }) => {
      const pos = 0;

      return {
        display: 'none',
        position: 'fixed',
        left: pos,
        top: pos,
        background:
          mode === 'dark'
            ? alpha(lighten(background, 0.1), 0.5)
            : darkBackground,
        boxShadow: mode === 'dark' ? sidebar.boxShadow : 'none',

        [`${breakpoints.up('lg')}`]: {
          display: 'flex',
          flexDirection: 'column'
        }
      };
    }
  ),

  DynamicContainer: styled(Drawer)(({ theme: { sidebar } }) => ({
    boxShadow: sidebar.boxShadow
  })),

  DynamicMenuContainer: styled(Container)(
    ({
      theme: {
        palette: { common, mode },
        header
      }
    }) => ({
      display: 'flex',
      flexDirection: 'column',
      background: mode === 'dark' ? common.white : header.darkBackground
    })
  )
};
