import { format, isSameDay } from 'date-fns';
import { formatDateToStartOfDay, getMerchantById } from 'src/helpers/functions';
import {
  formatCNPJ,
  formatContractStatus,
  formatCurrency
} from 'src/helpers/masks';
import { MerchantItemType } from 'src/stores/merchant';
import requester from '../requester';
import {
  ChargeItemResponseType,
  ChargesResponseType,
  GetChargeDashListDtoResponseType,
  GetChargeDashListFiltersType
} from './types';

const url = 'charge-dash';
const isAuthenticated = true;

export const chargeDashService = {
  getChargeDashList: async (
    filters: GetChargeDashListFiltersType,
    merchants: MerchantItemType[]
  ) => {
    try {
      const response = await requester<GetChargeDashListDtoResponseType>({
        isAuthenticated,
        url: `${url}/list`,
        method: 'GET'
      });

      // Mock
      // const response = { data: charges_mock };

      const {
        data: { charges }
      } = response;

      if (!charges) {
        throw new Error('There are no charges for this response.');
      }

      let currentCharges: ChargesResponseType = charges.map(
        ({
          amount,
          constitutedAmount,
          createdAt,
          debitBalance,
          dueDate,
          fee,
          id,
          merchantId,
          status
        }) => {
          const formatAmount = (amount: number) =>
            formatCurrency(amount, { inCents: true, removeSuffix: true });

          const formatDate = (date: Date) => format(date, 'dd/MM/yyyy');

          const merchant = getMerchantById(merchants, merchantId);
          const merchantCnpj = merchant?.cnpj ?? '';
          const currentDueDate = formatDateToStartOfDay(dueDate);
          const currentCreatedAt = formatDateToStartOfDay(createdAt);

          const charge: ChargeItemResponseType = {
            amount,
            id,
            createdAt: currentCreatedAt,
            constitutedAmount,
            debitBalance,
            dueDate: currentDueDate,
            fee,
            formattedAmount: formatAmount(amount),
            formattedConstitutedAmount: formatAmount(constitutedAmount),
            formattedCreatedAt: formatDate(currentCreatedAt),
            formattedDebitBalance: formatAmount(debitBalance),
            formattedDueDate: formatDate(currentDueDate),
            formattedFee: formatAmount(fee),
            formattedMerchantCnpj: formatCNPJ(merchantCnpj),
            formattedStatus: formatContractStatus(status),
            merchantName: merchant?.name ?? '',
            merchantCnpj,
            merchantId,
            status
          };

          return charge;
        }
      );

      if (filters?.externalId) {
        currentCharges = currentCharges.filter(
          ({ id }) => id === filters.externalId
        );
      }

      if (filters?.dueDate) {
        const filterDueDate = formatDateToStartOfDay(filters.dueDate);

        currentCharges = currentCharges.filter(({ dueDate }) => {
          return isSameDay(dueDate, filterDueDate);
        });
      }

      if (filters?.createdAt) {
        const filterCreatedAt = formatDateToStartOfDay(filters.createdAt);

        currentCharges = currentCharges.filter(({ createdAt }) => {
          return isSameDay(createdAt, filterCreatedAt);
        });
      }

      if (filters?.status) {
        currentCharges = currentCharges.filter(
          ({ status }) => status === filters.status
        );
      }

      if (filters.merchantId) {
        currentCharges = currentCharges.filter(
          ({ merchantId }) => merchantId === filters.merchantId
        );
      }

      return {
        ...response,
        data: {
          charges: currentCharges
        }
      };
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  }
};
