import { MerchantItemType } from 'src/stores/merchant';

export const handleQueryParams = (data: { [key: string]: any }) => {
  Object.keys(data).forEach((key) =>
    data[key] === undefined || data[key] === '' || data[key] === null
      ? delete data[key]
      : {}
  );
  const queryParams = new URLSearchParams(data).toString();

  return queryParams ? `?${queryParams}` : queryParams;
};

export const formatQueryParamsDate = (date: Date) =>
  date.toISOString().slice(0, 10);

export const normalizeString = (text: string) =>
  text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const formatDateToStartOfDay = (value: Date | string) => {
  const getDateWithoutTimezone = (value: string) => value.slice(0, 10);

  const date =
    typeof value === 'string'
      ? getDateWithoutTimezone(value)
      : getDateWithoutTimezone(value.toISOString());

  return new Date(`${date}T00:00:00.000`);
};

export const getMerchantById = (
  merchants: MerchantItemType[],
  merchantId: string
): MerchantItemType | null =>
  merchants.find(({ id }) => id === merchantId) ?? null;

export const getMerchantPropById = (
  merchants: MerchantItemType[],
  merchantId: string,
  prop: keyof MerchantItemType
): string => {
  const merchant = getMerchantById(merchants, merchantId);

  if (!merchant) {
    return '';
  }

  return `${merchant[prop]}`;
};
