import { action, makeAutoObservable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import {
  DecodedTokenRoleType,
  decodeToken,
  getToken,
  removeTokens,
  saveTokens
} from 'src/helpers/cookies/auth_user';
import { formatUserRoles } from 'src/helpers/masks';
import { isEmptyString } from 'src/helpers/validators';
import { userService } from 'src/services/user';
import { GlobalStore } from '../global';
import { LayoutStore } from '../layout';
import {
  CreateUserModalType,
  RolePermissionsType,
  UserSessionType,
  UsersType
} from './types';

export default class UserStore {
  layoutStore: LayoutStore;

  userSession: UserSessionType;
  users: UsersType;

  createUserModalVisibility: boolean;
  rolesOptions: DecodedTokenRoleType['name'][];

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.layoutStore = globalStore.LayoutStore;

    const userSessionToken = getToken();
    this.userSession = null;

    if (userSessionToken) {
      this.setUserSession(userSessionToken);
    } else {
      this.resetUserSession();
    }

    this.users = [];

    this.createUserModalVisibility = false;
    this.rolesOptions = ['admin', 'finance', 'support'];
  }

  @action.bound
  handleLogin = async (userName: string, password: string) => {
    try {
      this.layoutStore.showBackdrop();

      const response = await userService.postUserLogin({ userName, password });

      this.setUserSession(response.token);
      saveTokens(response);
    } catch (error) {
      enqueueSnackbar(
        'Usuário ou senha inválidos. Por favor, reveja os campos informados e tente novamente.',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  handleLogout = async () => {
    this.resetUserSession();
    removeTokens();
  };

  @action.bound
  resetUserSession = () => {
    this.userSession = null;
  };

  @action.bound
  setUserSession = (token: string) => {
    const {
      user: { login, email, id, roles }
    } = decodeToken(token);

    const rolePermissions: RolePermissionsType = {
      admin: {
        merchants: { view: true, optIn: true, optOut: true, register: true },
        orders: true,
        schedule: true,
        contracts: {
          guarantees: {
            view: true
          },
          list: {
            view: true,
            edit: true,
            syncStatus: true
          }
        },
        limit: {
          baseQuery: true,
          query: true,
          simulation: true
        },
        settings: {
          paymentsArrangements: {
            view: true,
            save: true,
            updateMerchants: true
          },
          users: { view: true, register: true }
        }
      },
      finance: {
        contracts: {
          guarantees: { view: true },
          list: {
            view: true,
            edit: true,
            syncStatus: true
          }
        },
        settings: {
          users: { register: false, view: true },
          paymentsArrangements: {
            save: false,
            updateMerchants: false,
            view: true
          }
        },
        limit: {
          baseQuery: true,
          query: true,
          simulation: true
        },
        merchants: {
          view: true,
          optIn: true,
          optOut: true,
          register: true
        },
        orders: true,
        schedule: true
      },
      support: {
        contracts: {
          guarantees: { view: true },
          list: {
            view: true,
            edit: false,
            syncStatus: false
          }
        },
        limit: {
          baseQuery: true,
          query: true,
          simulation: true
        },
        merchants: { view: true, optIn: false, optOut: false, register: false },
        orders: true,
        schedule: true,
        settings: {
          paymentsArrangements: {
            view: true,
            save: false,
            updateMerchants: false
          },
          users: { view: true, register: false }
        }
      }
    };

    const currentRoles = roles.map(({ name }) => name);

    this.userSession = {
      login,
      email,
      id,
      roles: currentRoles,
      formattedRoles: formatUserRoles(currentRoles),
      permissions: rolePermissions[roles[0].name]
    };
  };

  @action.bound
  setUsers = (users: UsersType) => {
    this.users = users;
  };

  @action.bound
  handleUsers = async () => {
    try {
      this.layoutStore.showBackdrop();

      const response = await userService.getUser();

      this.setUsers(response);
    } catch (error) {
      enqueueSnackbar(
        'Não foi possível carregar os usuários. Por favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  //#region Create User Modal
  @action.bound
  openCreateUserModal = () => {
    this.createUserModalVisibility = true;
  };

  @action.bound
  closeCreateUserModal = () => {
    this.createUserModalVisibility = false;
  };

  @action.bound
  createUser = async ({
    email,
    fullName,
    login,
    password,
    roles,
    verifyPassword
  }: CreateUserModalType) => {
    try {
      this.layoutStore.showBackdrop();

      const handleErrorMessage = (message: string) =>
        enqueueSnackbar(message, { variant: 'warning' });

      if (isEmptyString(fullName)) {
        handleErrorMessage('Informe um nome completo válido');
        return;
      }

      if (isEmptyString(login)) {
        handleErrorMessage('Informe um login válido');
        return;
      }

      if (isEmptyString(email)) {
        handleErrorMessage('Informe um e-mail válido');
        return;
      }

      if (isEmptyString(password) || isEmptyString(verifyPassword)) {
        handleErrorMessage('Informe uma senha válida');
        return;
      }

      if (password !== verifyPassword) {
        handleErrorMessage('As senhas devem ser iguais');
        return;
      }

      if (roles?.length === 0) {
        handleErrorMessage('Informe pelo menos um cargo');
        return;
      }

      await userService.postUser({ login, email, password, fullName, roles });

      this.closeCreateUserModal();
      await this.handleUsers();

      enqueueSnackbar('Usuário cadastrado com sucesso.', {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(
        'Não foi possível cadastrar o usuário. Por favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };
  //#endregion
}
