import { Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { PermissionTooltip } from 'src/components/permissionTooltip';
import { withStores } from 'src/stores';
import { Styled } from './styles';
import { NavTabsProps, TabType } from './types';

export const NavTabs = withStores<NavTabsProps>(
  ['RouterStore'],
  ({
    RouterStore,
    routeId,
    variant = 'scrollable',
    scrollButtons = 'auto',
    ...props
  }: RequiredProp<NavTabsProps, 'RouterStore'>) => {
    const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);
    const [tabs, setTabs] = useState<TabType[]>([]);
    const location = useLocation();

    useEffect(() => {
      setTabs([]);
      setActiveTabIndex(null);

      const route = RouterStore.privateRoutes.find(
        (item) => item.routeId === routeId
      );

      if (!route) {
        return;
      }

      const { module } = route;

      const tabs: TabType[] = RouterStore.privateRoutes
        .filter((item) => item?.module === module && item?.isTab)
        .map(({ title, path, hasViewPermission, routeId }) => ({
          routeId: routeId ?? '',
          label: title ?? '',
          disabled: !hasViewPermission,
          to: `/${path}`
        }));

      if (!tabs) {
        return;
      }

      const index = tabs.findIndex((item) => item.routeId === routeId);

      if (index === -1) {
        return;
      }

      setTabs(tabs);
      setActiveTabIndex(index);
    }, [location]);

    return activeTabIndex !== null ? (
      <Styled.Container
        {...props}
        variant={variant}
        scrollButtons={scrollButtons}
        value={activeTabIndex}
      >
        {tabs.map(({ label, to, disabled }, index) => (
          <PermissionTooltip
            disabled={disabled}
            title="Você não tem permissão para acessar essa página"
            key={index}
          >
            <Tab
              disabled={disabled}
              component={NavLink}
              label={label}
              to={to}
            />
          </PermissionTooltip>
        ))}
      </Styled.Container>
    ) : null;
  }
);
