import { useTheme } from '@mui/material';
import { withStores } from 'src/stores';
import { Menu } from './menu';
import { Styled } from './styles';
import { SidebarProps } from './types';

export const Sidebar = withStores(
  ['LayoutStore'],
  ({ LayoutStore }: Required<SidebarProps>) => {
    const theme = useTheme();

    const { sidebarVisibility, toggleSidebar } = LayoutStore;

    const closeSidebar = () => toggleSidebar();

    return (
      <>
        <Styled.FixedMenuContainer>
          <Menu />
        </Styled.FixedMenuContainer>
        <Styled.DynamicContainer
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={sidebarVisibility}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <Styled.DynamicMenuContainer>
            <Menu />
          </Styled.DynamicMenuContainer>
        </Styled.DynamicContainer>
      </>
    );
  }
);
