import { format as dateFnsFormat } from 'date-fns';
import acquirers_subacquirers from 'src/mocks/acquirers_subacquirers';
import registers from 'src/mocks/registers';
import { validateOnlyNumber } from './validators';

export const formatCurrency = (
  value: null | number | undefined,
  options?: {
    inCents?: boolean;
    removeSuffix?: boolean;
  }
) => {
  let currentValue: number = !value ? 0 : value;

  if (options?.inCents) {
    currentValue = currentValue / 100;
  }

  let formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(currentValue);

  if (options?.removeSuffix) {
    formattedValue = formattedValue.replace(/[^0-9.,]/g, '');
  }

  return formattedValue;
};

export const formatPercent = (
  value: null | number | undefined,
  suffix: boolean = false
) => `${(value ?? 0).toFixed(2)}${suffix ? '%' : ''}`;

export const formatDateWithoutUTC = (
  value: string | number | Date | undefined | null,
  format: string
) => {
  if (!value) {
    return '';
  }

  const createDateWithoutUTC = (value: string | number | Date) => {
    const dt = new Date(value);

    return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  };

  return dateFnsFormat(createDateWithoutUTC(value), format);
};

export const formatCNPJ = (value: string) => {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '$1.$2');
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');

  return value;
};

export const maskNumbers = (numbers: string) => numbers.replace(/[^0-9,]/g, '');

export const formatAcquirerSubacquirer = (document: string) =>
  acquirers_subacquirers.find(
    (item) => item.document === validateOnlyNumber(document)
  )?.name ?? formatCNPJ(document);

export const formatRegister = (document: string) =>
  registers.find((item) => item.document === validateOnlyNumber(document))
    ?.name ?? formatCNPJ(document);

export const formatUserRole = (role: string) => {
  let formattedRole = role;

  switch (role.toLowerCase()) {
    case 'admin': {
      formattedRole = 'Administrador';
      break;
    }
    case 'finance': {
      formattedRole = 'Financeiro';
      break;
    }
    case 'support': {
      formattedRole = 'Suporte';
      break;
    }
  }

  return formattedRole;
};

export const formatUserRoles = (roles: string[]) =>
  roles.map((role) => formatUserRole(role)).join(', ');

export const formatContractStatus = (value: string) => {
  let status = '';

  switch (value) {
    case 'CREATED':
      status = 'Pendente';
      break;
    case 'PARTIALLY_PAID':
      status = 'Parcialmente Pago';
      break;
    case 'PAID':
      status = 'Pago';
      break;
    case 'PARTIALLY_CANCELLED':
      status = 'Parcialmente Cancelado';
      break;
    case 'CANCELLED':
      status = 'Cancelado';
      break;
    case 'ERROR':
      status = 'Erro';
      break;
  }

  return status;
};

export const maskOnlyNumbers = (value: string) => value.replace(/[^0-9]/g, '');
