import { addDays, isValid } from 'date-fns';
import { action, makeAutoObservable, reaction } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { formatQueryParamsDate } from 'src/helpers/functions';
import { formatCurrency, formatDateWithoutUTC } from 'src/helpers/masks';
import {
  GetScheduleDtoResponseType,
  GetScheduleFiltersType,
  scheduleService
} from 'src/services/schedule';
import { GlobalStore } from '../global';
import { LayoutStore } from '../layout';
import { MerchantItemType, MerchantStore, MerchantsType } from '../merchant';
import {
  AllMerchantsLimitsItemType,
  AllMerchantsLimitsType,
  LimitAllMerchantsLimitsType,
  LimitFiltersType,
  LimitMerchantLimitsType,
  LimitsType,
  MerchantLimitsType,
  MerchantsSchedulesItemType
} from './types';

export default class LimitStore {
  layoutStore: LayoutStore;
  merchantStore: MerchantStore;

  merchantLimits: LimitMerchantLimitsType;
  allMerchantsLimits: LimitAllMerchantsLimitsType;

  filters: LimitFiltersType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.layoutStore = globalStore.LayoutStore;
    this.merchantStore = globalStore.MerchantStore;

    this.merchantLimits = {
      items: [],
      queryEnabled: false
    };

    this.allMerchantsLimits = {
      items: [],
      queryEnabled: true
    };

    const today = new Date();
    this.filters = {
      online: false,
      fromDate: addDays(today, 13),
      toDate: addDays(today, 30)
    };

    this.updateMerchantLimitsQueryEnabled();
    this.updateAllMerchantsLimitsQueryEnabled();
  }

  formatDate = (date: string | Date) =>
    formatDateWithoutUTC(date, 'dd/MM/yyyy');

  formatAmount = (amount: number) =>
    formatCurrency(amount, { removeSuffix: true });

  validateFilterDates = () =>
    isValid(this.filters.fromDate) && isValid(this.filters.toDate);

  formatLimitId = (index: number) => `limit__${index};`;

  @action.bound
  setFilters = (filters: LimitStore['filters']) => {
    this.filters = filters;
  };

  @action
  onSelectMerchant = (
    value: {
      id: string;
      label: string;
    } | null
  ) => {
    if (value) {
      this.merchantStore.selectMerchant(value.id);
    } else {
      this.merchantStore.selectMerchant('');
    }
  };

  //#region MerchantLimits
  @action.bound
  handleLimit = async () => {
    try {
      this.layoutStore.showBackdrop();

      if (!this.merchantLimits.queryEnabled) {
        return;
      }

      if (!this.merchantStore.selectedMerchant?.id) {
        throw new Error('selectedMerchant is undefined or null');
      }

      const filters: GetScheduleFiltersType = {
        merchantId: this.merchantStore.selectedMerchant?.id,
        fromDate: formatQueryParamsDate(this.filters.fromDate),
        toDate: formatQueryParamsDate(this.filters.toDate),
        online: this.filters.online
      };

      const response = await scheduleService.getSchedule(filters);

      if (!response.data.schedule) {
        throw new Error('schedule is undefined or null');
      }

      const { schedule } = response.data;

      const limits: LimitsType = [];

      for (let i = 0; i < schedule.limits.length; i++) {
        limits.push({
          id: this.formatLimitId(i),
          date: schedule.limits[i].date,
          amount:
            schedule.limits[i].amount + (i > 0 ? limits[i - 1].amount : 0),
          totalAmount:
            schedule.totalLimits[i].amount +
            (i > 0 ? limits[i - 1].totalAmount : 0),
          totalLimits100Free:
            schedule.totalLimits100Free[i].amount +
            (i > 0 ? limits[i - 1].totalLimits100Free : 0)
        });
      }

      const merchantsLimits: MerchantLimitsType = limits.map(
        ({ date, amount, totalAmount, totalLimits100Free, ...limit }) => ({
          ...limit,
          date: this.formatDate(date),
          amount: this.formatAmount(amount),
          totalAmount: this.formatAmount(totalAmount),
          totalLimits100Free: this.formatAmount(totalLimits100Free)
        })
      );

      this.setMerchantLimits({
        ...this.merchantLimits,
        items: merchantsLimits
      });
    } catch (error) {
      console.error(error);
      this.setMerchantLimits({ ...this.merchantLimits, items: [] });
      enqueueSnackbar(
        'Não foi possível recuperar os limites desse estabelecimento. Por favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  setMerchantLimits = (merchantLimits: LimitStore['merchantLimits']) => {
    this.merchantLimits = merchantLimits;
  };

  updateMerchantLimitsQueryEnabled = () =>
    reaction(
      () => [this.merchantStore.selectedMerchant, this.filters],
      () => {
        this.merchantLimits.queryEnabled =
          !!this.merchantStore.selectedMerchant && this.validateFilterDates();
      }
    );
  //#endregion

  //#region AllMerchantLimits
  @action.bound
  handleAllMerchantsLimits = async () => {
    if (!this.allMerchantsLimits.queryEnabled) {
      return;
    }

    let allMerchantsLimits = await this.fetchAllMerchantsLimits(
      this.merchantStore.merchants
    );

    allMerchantsLimits = allMerchantsLimits.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    this.setAllMerchantsLimits({
      ...this.allMerchantsLimits,
      items: allMerchantsLimits
    });
  };

  @action.bound
  validateAllMerchantLimitItemError = (
    error?: AllMerchantsLimitsItemType['error']
  ) => !!error && error !== 'merchant must be opted in';

  @action.bound
  getFailedAllMerchantsLimitsItems = () =>
    this.allMerchantsLimits.items.filter(({ error }) =>
      this.validateAllMerchantLimitItemError(error)
    );

  @action.bound
  fetchAllMerchantsLimits = async (merchants: MerchantsType) => {
    try {
      this.layoutStore.showBackdrop();

      const getLimit = async (
        merchant: MerchantItemType
      ): Promise<{
        merchant: MerchantItemType;
        schedule?: GetScheduleDtoResponseType['schedule'];
        error?: any;
      } | null> => {
        try {
          if (merchant.name === 'Jesus Noel Suarez Rubi') {
            return null;
          }

          console.log(
            `Calculando limite --> Nome: ${merchant.name} | ID: ${merchant.id}`
          );

          const filters: GetScheduleFiltersType = {
            merchantId: merchant.id,
            fromDate: formatQueryParamsDate(this.filters.fromDate),
            toDate: formatQueryParamsDate(this.filters.toDate),
            online: this.filters.online
          };

          const {
            data: { message, schedule }
          } = await scheduleService.getSchedule(filters);

          return {
            merchant,
            schedule,
            error: message ?? undefined
          };
        } catch (error) {
          console.error(error);
          return { merchant, error: error ?? 'fetching error' };
        }
      };

      let allMerchantsLimits: AllMerchantsLimitsType = [];
      const chunkSize = 10;
      const chunckedMerchants: MerchantsType[] = [];
      const merchantsSchedules: MerchantsSchedulesItemType[] = [];
      const merchantsLength = merchants.length;
      // const merchantsLength = 10; // Mock

      for (let i = 0; i < merchantsLength; i += chunkSize) {
        const chunk = Array.from(merchants.slice(i, i + chunkSize));

        chunckedMerchants.push(chunk);
      }

      for (const chunkMerchants of chunckedMerchants) {
        const requests = chunkMerchants.map((item) => getLimit(item));

        const merchantSchedulesResponse = await Promise.all(requests);

        merchantsSchedules.push(
          ...merchantSchedulesResponse.filter(
            (item): item is MerchantsSchedulesItemType => !!item?.merchant.id
          )
        );
      }

      merchantsSchedules.forEach(({ merchant, schedule, error }) => {
        const limits: LimitsType = [];

        if (schedule?.limits && schedule.limits.length >= 0) {
          for (let i = 0; i < schedule.limits.length; i++) {
            limits.push({
              id: this.formatLimitId(i),
              date: schedule.limits[i].date,
              amount:
                schedule.limits[i].amount + (i > 0 ? limits[i - 1].amount : 0),
              totalAmount:
                schedule.totalLimits[i].amount +
                (i > 0 ? limits[i - 1].totalAmount : 0),
              totalLimits100Free:
                schedule.totalLimits100Free[i].amount +
                (i > 0 ? limits[i - 1].totalLimits100Free : 0)
            });
          }
        }

        const limit = schedule
          ? this.formatAmount(
              !!limits.length ? limits[limits.length - 1].amount : 0
            )
          : '';
        const today = this.formatDate(new Date());
        const date = this.formatDate(new Date('2023-08-21'));

        allMerchantsLimits.push({
          id: merchant?.id,
          name: merchant?.name,
          cnpj: merchant?.cnpj,
          createdAt: date,
          status: 'APROVADO',
          limit,
          email: '',
          error: error ?? '',
          lastBlockDate: '',
          totalLimit: limit,
          lastUpdate: today,
          docStatus: 'completo',
          dataAppDocs: date,
          dataAnaliseLimit: today,
          totalAmount: schedule
            ? this.formatAmount(
                !!limits.length ? limits[limits.length - 1].totalAmount : 0
              )
            : '',
          totalLimits100Free: schedule
            ? this.formatAmount(
                !!limits.length
                  ? limits[limits.length - 1].totalLimits100Free
                  : 0
              )
            : ''
        });
      });

      return allMerchantsLimits;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  handleFailedAllMerchantsLimits = async () => {
    const failedMerchants = this.getFailedAllMerchantsLimitsItems();

    const parsedFailedMerchants: MerchantsType = failedMerchants
      .map((failedMerchant) => {
        const merchant = this.merchantStore.merchants.find(
          (merchant) => merchant.id === failedMerchant.id
        );

        if (merchant) {
          return merchant;
        } else {
          return null;
        }
      })
      .filter((item): item is MerchantItemType => item !== null);

    const allMerchantsLimits = await this.fetchAllMerchantsLimits(
      parsedFailedMerchants
    );

    let allMerchantsLimitsItems = Array.from(this.allMerchantsLimits.items);

    allMerchantsLimits.forEach((item) => {
      const index = allMerchantsLimitsItems.map((x) => x.id).indexOf(item.id);

      allMerchantsLimitsItems[index] = item;
    });

    this.setAllMerchantsLimits({
      ...this.allMerchantsLimits,
      items: allMerchantsLimitsItems
    });
  };

  @action.bound
  setAllMerchantsLimits = (
    allMerchantsLimits: LimitStore['allMerchantsLimits']
  ) => {
    this.allMerchantsLimits = allMerchantsLimits;
  };

  updateAllMerchantsLimitsQueryEnabled = () =>
    reaction(
      () => this.filters,
      () => {
        this.allMerchantsLimits.queryEnabled = this.validateFilterDates();
      }
    );
  //#endregion
}
