import { addDays, differenceInDays, format, isValid } from 'date-fns';
import { action, makeAutoObservable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import {
  formatDateToStartOfDay,
  formatQueryParamsDate
} from 'src/helpers/functions';
import { formatCNPJ, formatCurrency } from 'src/helpers/masks';
import { dynamicRoutes } from 'src/helpers/navigationRoutes';
import {
  GetOrdersFiltersType,
  GetOrdersItemDtoResponseType,
  ordersService
} from 'src/services/orders';
import { GlobalStore } from '../global';
import { LayoutStore } from '../layout';
import { MerchantStore } from '../merchant';
import { SuppliersStore } from '../suppliers';
import {
  OrdersByDateItemType,
  OrdersByDateType,
  OrdersFiltersType,
  OrdersType
} from './types';

export default class OrdersStore {
  layoutStore: LayoutStore;
  suppliersStore: SuppliersStore;
  merchantStore: MerchantStore;

  orders: OrdersType;
  ordersByDate: OrdersByDateType;

  filters: OrdersFiltersType;
  queryEnabled: boolean;

  dateTypes: SelectOptionsType;
  billetStatuses: SelectOptionsType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.layoutStore = globalStore.LayoutStore;
    this.suppliersStore = globalStore.SuppliersStore;
    this.merchantStore = globalStore.MerchantStore;

    this.orders = [];

    this.ordersByDate = {
      createdOrders: [],
      overdueOrders: [],
      paidOrders: []
    };

    const today = new Date();

    this.filters = {
      billetStatus: '',
      orderId: '',
      fromDate: today,
      toDate: addDays(today, 7),
      dateType: 'createdDate'
    };

    this.queryEnabled = true;

    this.dateTypes = [
      {
        value: 'createdDate',
        label: 'Data de Criação'
      },
      {
        value: 'dueDate',
        label: 'Data de Vencimento'
      }
    ];

    this.billetStatuses = [
      { value: 'all', label: 'Todos' },
      {
        value: 'finalized',
        label: 'A Vencer'
      },
      {
        value: 'active',
        label: 'Aberto'
      },
      {
        value: 'overdue',
        label: 'Vencido'
      },
      {
        value: 'programmed',
        label: 'Não Gerado'
      }
    ];
  }

  handleSummary = (orders: OrdersType) => {
    const handleAmount = (orders: OrdersType) =>
      orders.reduce((a, { amount }) => a + amount, 0);

    const finalizedOrders = orders.filter(
      ({ status }) => status === 'FINALIZED'
    );

    const programmedOrders = orders.filter(
      ({ status }) => status === 'PROGRAMMED'
    );

    const activeOrders = orders.filter(
      ({ billingStatus }) => billingStatus === 'ACTIVE'
    );

    const overdueOrders = orders.filter(
      ({ billingStatus }) =>
        billingStatus === 'OVERDUE' ||
        billingStatus === 'OVERDUE_FEE_CHARGE' ||
        billingStatus === 'EXPIRED'
    );

    const paidOrders = orders.filter(
      ({ billingStatus }) => billingStatus === 'PAID'
    );

    const totalActiveOrdersAmount = handleAmount(activeOrders);
    const totalOverdueOrdersAmount = handleAmount(overdueOrders);

    return {
      totalActiveOrders: activeOrders.length,
      totalActiveOrdersAmount,
      totalAmount: totalActiveOrdersAmount + totalOverdueOrdersAmount,
      totalFinalizedOrders: finalizedOrders.length,
      totalFinalizedOrdersAmount: handleAmount(finalizedOrders),
      totalOverdueOrders: overdueOrders.length,
      totalOverdueOrdersAmount,
      totalPaidOrders: paidOrders.length,
      totalPaidOrdersAmount: handleAmount(paidOrders),
      totalProgrammedOrders: programmedOrders.length,
      totalProgrammedOrdersAmount: handleAmount(programmedOrders)
    };
  };

  formatDate = (date: Date) => format(date, 'dd/MM/yyyy');

  formatQueryParamsSelect = (value: string) => (value === 'all' ? '' : value);

  formatQueryParamsDate = (date: Date) => date.toISOString().slice(0, 10);

  @action.bound
  handleOrders = async () => {
    try {
      this.layoutStore.showBackdrop();

      if (!this.queryEnabled) {
        return;
      }

      const formatQueryParamsSelect = (value: string) =>
        value === 'all' ? '' : value;

      const filters: GetOrdersFiltersType = {
        merchantId: this.merchantStore.selectedMerchant?.id ?? '',
        orderId: this.filters.orderId,
        dateType: this.filters.dateType,
        supplierId: formatQueryParamsSelect(
          this.suppliersStore.selectedSupplierId
        ),
        billetStatus: formatQueryParamsSelect(this.filters.billetStatus),
        fromDate: formatQueryParamsDate(this.filters.fromDate),
        toDate: formatQueryParamsDate(this.filters.toDate)
      };

      const { data } = await ordersService.getOrders(filters);

      const currentOrders: OrdersType = data.items.map(
        ({ billing, merchant: { document, fantasyName }, id, ...item }) => {
          const { amount, status } = billing;
          const merchantDocument = document;
          const createdAt = formatDateToStartOfDay(item.createdAt);
          const paidAt = billing.paidAt
            ? formatDateToStartOfDay(billing.paidAt)
            : null;
          const deliveryDate = formatDateToStartOfDay(item.deliveryDate);
          const overdueAt = formatDateToStartOfDay(billing.overdueAt);

          return {
            ...item,
            id,
            deliveryDate,
            overdueAt,
            paidAt,
            createdAt,
            amount,
            billingStatus: status,
            billingLink: dynamicRoutes({ id }).zakPayOrderPaymentId,
            merchantDocument,
            merchantFantasyName: fantasyName,
            formattedMerchantDocument: formatCNPJ(merchantDocument),
            formattedCreatedAt: this.formatDate(createdAt),
            formattedDeliveryDate: this.formatDate(deliveryDate),
            formattedAmount: formatCurrency(amount, { removeSuffix: true }),
            formattedOverdueAt: this.formatDate(overdueAt),
            formattedPaidAt: paidAt ? this.formatDate(paidAt) : ''
          };
        }
      );

      this.setOrders(currentOrders ?? []);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        'Erro ao recuperar os pedidos. Por Favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  handleDashboardOrders = async () => {
    try {
      if (!this.queryEnabled) {
        return;
      }

      this.layoutStore.showBackdrop();

      const filters: GetOrdersFiltersType = {
        merchantId: this.merchantStore.selectedMerchant?.id ?? '',
        dateType: 'createdAt',
        supplierId: this.formatQueryParamsSelect(
          this.suppliersStore.selectedSupplierId
        ),
        fromDate: formatQueryParamsDate(this.filters.fromDate),
        toDate: formatQueryParamsDate(this.filters.toDate)
      };

      const { data } = await ordersService.getOrders(filters);
      const { items } = data;

      const formatDate = (date: Date) =>
        new Date(`${date.toISOString().slice(0, 10)}T00:00:00.000`);

      const filterByDate = (orderDate: string, date: Date) =>
        orderDate.slice(0, 10) === date.toISOString().slice(0, 10);

      const formatOrder = (
        date: Date,
        orders: GetOrdersItemDtoResponseType[]
      ): OrdersByDateItemType => ({
        date: format(date, 'dd/MM/yyyy'),
        orders: orders.map(({ status, id, createdAt, billing, merchant }) => ({
          createdAt,
          status,
          id,
          billing,
          merchant
        }))
      });

      const daysDiff = differenceInDays(
        formatDate(this.filters.toDate),
        formatDate(this.filters.fromDate)
      );

      const datesInterval = Array.from({ length: daysDiff + 1 }, (_, index) =>
        formatDate(addDays(this.filters.fromDate, index))
      );

      const currentOrdersByDate: OrdersByDateType = {
        createdOrders: [],
        overdueOrders: [],
        paidOrders: []
      };

      datesInterval.forEach((date) => {
        const createdOrders = items.filter(({ createdAt }) =>
          filterByDate(createdAt, date)
        );
        const paidOrders = items.filter(
          ({ billing: { paidAt } }) => paidAt && filterByDate(paidAt, date)
        );
        const overdueOrders = items.filter(({ billing: { overdueAt } }) =>
          filterByDate(overdueAt, date)
        );

        currentOrdersByDate.createdOrders.push(
          formatOrder(date, createdOrders)
        );
        currentOrdersByDate.overdueOrders.push(
          formatOrder(date, overdueOrders)
        );
        currentOrdersByDate.paidOrders.push(formatOrder(date, paidOrders));
      });

      this.setOrdersByDate(currentOrdersByDate);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        'Erro ao recuperar os pedidos. Por Favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  setOrdersByDate = (orders: OrdersByDateType) => {
    this.ordersByDate = orders;
  };

  @action.bound
  setOrders = (orders: OrdersType) => {
    this.orders = orders;
  };

  @action.bound
  setFilters = (filters: OrdersStore['filters']) => {
    this.filters = filters;
    this.queryEnabled =
      !!this.filters.dateType &&
      isValid(this.filters.toDate) &&
      isValid(this.filters.fromDate);
  };
}
