import { Tooltip, styled, tooltipClasses } from '@mui/material';
import { TooltipProps } from './types';

export const Styled = {
  Tooltip: styled(({ className, variant, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme: { palette }, variant }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor:
        variant === 'dark' ? palette.secondary.dark : palette.grey[100],
      color: variant === 'dark' ? palette.common.white : palette.secondary.dark,
      border: `1px solid ${
        variant === 'dark' ? palette.secondary.main : palette.grey[300]
      }`,
      maxWidth: 'none'
    }
  }))
};
