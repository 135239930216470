import { action, makeAutoObservable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { configReadService } from 'src/services/configRead';
import { merchantService } from 'src/services/merchant';
import { GlobalStore } from '../global';
import { LayoutStore } from '../layout';
import { MerchantItemType, MerchantStore } from '../merchant';

export default class ConfigStore {
  layoutStore: LayoutStore;
  merchantStore: MerchantStore;

  paymentsArrangements: string[];
  paymentsArrangementsOptions: string[];

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.layoutStore = globalStore.LayoutStore;
    this.merchantStore = globalStore.MerchantStore;

    this.paymentsArrangements = [];
    this.paymentsArrangementsOptions = ['MCC', 'VCC'];
  }

  @action.bound
  setPaymentsArrangements = (
    paymentsArrangements: ConfigStore['paymentsArrangements']
  ) => {
    this.paymentsArrangements = paymentsArrangements;
  };

  @action.bound
  handlePaymentsArrangementsConfig = async () => {
    try {
      this.layoutStore.showBackdrop();

      const response =
        await configReadService.getConfigReadZakScheduleRegister();

      this.setPaymentsArrangements(
        response.data.config.value.paymentsArrangements
      );
    } catch (error) {
      enqueueSnackbar(
        'Não foi possível carregar as configurações de arranjos de pagamentos. Por favor, tente novamente mais tarde.',
        {
          variant: 'error'
        }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  savePaymentsArrangementsConfig = async () => {
    try {
      this.layoutStore.showBackdrop();

      const response = await configReadService.putConfigReadZakScheduleRegister(
        {
          value: {
            acquirers: ['99T'],
            paymentsArrangements: this.paymentsArrangements
          },
          id: 'zak',
          kind: 'schedule-register'
        }
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        'Não foi possível aplicar as configurações de arranjos de pagamentos. Por favor, tente novamente mais tarde.',
        {
          variant: 'error'
        }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  updateMerchantsOptIn = async () => {
    try {
      this.layoutStore.showBackdrop();

      const handleMerchantUpdateOptIn = async (
        merchantIds: string[]
      ): Promise<boolean> => {
        try {
          console.log(`Atualizando Opt-In ID's: ${merchantIds}`);

          const response = merchantService.postMerchantUpdateOptIn({
            merchantIds
          });

          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      };

      const merchants = this.merchantStore.merchants;
      const merchantsChunkSize = 16;
      const chunckedMerchants: MerchantItemType[][] = [];
      const merchantsIdsChunkSize = merchantsChunkSize / 4;
      const chunckedMerchantsIds: string[][][] = [];

      for (let i = 0; i < merchants.length; i += merchantsChunkSize) {
        const chunk = Array.from(merchants.slice(i, i + merchantsChunkSize));

        chunckedMerchants.push(chunk);
      }

      console.log('chunckedMerchants --> ', chunckedMerchants);

      chunckedMerchants.forEach((cm) => {
        const chunckMerchantsIds: string[][] = [];

        for (let i = 0; i < cm.length; i += merchantsIdsChunkSize) {
          const chunk = Array.from(cm.slice(i, i + merchantsIdsChunkSize));

          chunckMerchantsIds.push(chunk.map(({ id }) => id));
        }

        chunckedMerchantsIds.push(chunckMerchantsIds);
      });

      console.log('chunckedMerchantsIds --> ', chunckedMerchantsIds);

      let allResponses: boolean[][] = [];

      for (const chunkMerchantsIds of chunckedMerchantsIds) {
        const requests = chunkMerchantsIds.map((item) =>
          handleMerchantUpdateOptIn(item)
        );

        allResponses = [...allResponses, await Promise.all(requests)];
      }

      if (allResponses.flat().includes(false)) {
        throw new Error('It was not possible to update the entire base.');
      }

      enqueueSnackbar('Clientes da base atualizados com sucesso!', {
        variant: 'success'
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        'Não foi possível atualizar todos os clientes da base. Por favor, tente novamente mais tarde.',
        {
          variant: 'error'
        }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };
}
