import {
  addDays,
  differenceInBusinessDays,
  differenceInWeeks,
  isValid
} from 'date-fns';
import { action, makeAutoObservable, reaction } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { formatQueryParamsDate } from 'src/helpers/functions';
import {
  GetDetailedScheduleFiltersType,
  detailedScheduleService
} from 'src/services/detailedSchedule';
import { GlobalStore } from '../global';
import { LayoutStore } from '../layout';
import { MerchantStore } from '../merchant';
import {
  ScheduleFiltersType,
  ScheduleSummaryType,
  ScheduleType
} from './types';

export default class ScheduleStore {
  layoutStore: LayoutStore;
  merchantStore: MerchantStore;

  schedule: ScheduleType;

  filters: ScheduleFiltersType;
  queryEnabled: boolean;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.layoutStore = globalStore.LayoutStore;
    this.merchantStore = globalStore.MerchantStore;

    this.schedule = [];

    const today = new Date();

    this.filters = {
      fromDate: addDays(today, 1),
      toDate: addDays(today, 8),
      paymentArrangement: '',
      online: false
    };
    this.queryEnabled = false;

    this.updateQueryEnabled();
  }

  handleSummary = (rows: ScheduleType): ScheduleSummaryType => {
    const uniqueAcquirers = new Set();
    const uniqueArrangements = new Set();
    const uniqueFreeSchedulesDays = new Set();
    const uniqueBlockedSchedulesDays = new Set();
    const uniqueSchedulesDays = new Set();

    let totalBlockedAmount = 0;
    let totalFreeAmount = 0;
    let averageAmount = 0;
    let averageFreeAmount = 0;
    let averageBlockedAmount = 0;
    let totalPercFreeAmount = 0;
    let totalPercBlockedAmount = 0;

    rows.forEach(
      ({
        acquirer,
        freeAmount,
        blockedAmount,
        liquidationDate,
        paymentArrangement
      }) => {
        const liqDate = liquidationDate.toISOString().slice(0, 10);

        uniqueAcquirers.add(acquirer);
        uniqueArrangements.add(paymentArrangement);
        uniqueSchedulesDays.add(liqDate);

        if (freeAmount > 0) {
          uniqueFreeSchedulesDays.add(liqDate);

          totalFreeAmount += freeAmount;
        }

        if (blockedAmount > 0) {
          uniqueBlockedSchedulesDays.add(liqDate);

          totalBlockedAmount += blockedAmount;
        }
      }
    );

    const totalAmount = totalFreeAmount + totalBlockedAmount;

    const totalFreeSchedules = Array.from(uniqueFreeSchedulesDays).length;
    const totalBlockedSchedules = Array.from(uniqueBlockedSchedulesDays).length;
    const totalSchedules = Array.from(uniqueSchedulesDays).length;

    const handlePercAmount = (amount: number) => (amount * 100) / totalAmount;

    const currentTotalPercFreeAmount = handlePercAmount(totalFreeAmount);
    const currentTotalPercBlockedAmount = handlePercAmount(totalBlockedAmount);

    if (!isNaN(currentTotalPercFreeAmount)) {
      totalPercFreeAmount = currentTotalPercFreeAmount;
    }

    if (!isNaN(currentTotalPercBlockedAmount)) {
      totalPercBlockedAmount = currentTotalPercBlockedAmount;
    }

    if (totalSchedules > 0 && totalAmount > 0) {
      averageAmount = totalAmount / totalSchedules;
    }

    if (totalFreeAmount > 0 && totalFreeSchedules) {
      averageFreeAmount = totalFreeAmount / totalFreeSchedules;
    }

    if (totalBlockedAmount > 0 && totalBlockedSchedules > 0) {
      averageBlockedAmount = totalBlockedAmount / totalBlockedSchedules;
    }

    const currentToDate = addDays(this.filters.toDate, 1);
    const periodBusinessDays = differenceInBusinessDays(
      currentToDate,
      this.filters.fromDate
    );
    let periodWeeks = differenceInWeeks(currentToDate, this.filters.fromDate);

    if (periodWeeks === 0) {
      periodWeeks++;
    }

    return {
      averageAmount,
      averageBlockedAmount,
      averageFreeAmount,
      totalAcquirers: Array.from(uniqueAcquirers).length,
      totalAmount,
      totalArrangements: Array.from(uniqueArrangements).length,
      totalBlockedAmount,
      totalBlockedSchedules,
      totalFreeAmount,
      totalFreeSchedules,
      totalPercBlockedAmount,
      totalPercFreeAmount,
      totalSchedules,
      percSchedulesPerPeriod: (totalSchedules * 100) / periodBusinessDays,
      averageSchedulesPerWeek: totalSchedules / periodWeeks
    };
  };

  @action.bound
  handleSchedule = async () => {
    try {
      this.layoutStore.showBackdrop();

      if (!this.queryEnabled) {
        return;
      }

      if (!this.merchantStore.selectedMerchant) {
        throw new Error('this.merchantStore.selectedMerchant is null');
      }

      const filters: GetDetailedScheduleFiltersType = {
        online: this.filters.online,
        paymentArrangement: this.filters.paymentArrangement,
        fromDate: formatQueryParamsDate(this.filters.fromDate),
        toDate: formatQueryParamsDate(this.filters.toDate)
      };

      const schedule = await detailedScheduleService.getDetailedSchedule(
        this.merchantStore.selectedMerchant,
        filters
      );

      this.setSchedule(schedule ?? []);
    } catch (error) {
      enqueueSnackbar(
        'Não foi possível recuperar a agenda. Por favor, tente novamente mais tarde',
        { variant: 'error' }
      );

      this.setSchedule([]);
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  @action.bound
  setSchedule = (schedule: ScheduleType) => {
    this.schedule = schedule;
  };

  @action.bound
  setFilters = (filters: ScheduleFiltersType) => {
    this.filters = filters;
  };

  @action.bound
  onSelectPaymentArrangement = (
    value: {
      id: string;
      label: string;
    } | null
  ) => {
    if (value) {
      this.setFilters({
        ...this.filters,
        paymentArrangement: value.id
      });
    } else {
      this.setFilters({
        ...this.filters,
        paymentArrangement: ''
      });
    }
  };

  @action.bound
  onSelectMerchant = (
    value: {
      id: string;
      label: string;
    } | null
  ) => {
    if (value) {
      this.merchantStore.selectMerchant(value.id);
    } else {
      this.merchantStore.selectMerchant('');
    }
  };

  updateQueryEnabled = () =>
    reaction(
      () => [this.merchantStore.selectedMerchant, this.filters],
      () => {
        this.queryEnabled =
          !!this.merchantStore.selectedMerchant &&
          isValid(this.filters.fromDate) &&
          isValid(this.filters.toDate) &&
          !!this.filters.paymentArrangement;
      }
    );
}
