interface BaseDetailedScheduleDtoType {
  registerEntity: string;
  acquirerSubacquirerCNPJ: string;
  paymentArrangement: string;
  liquidationDate: string;
  amount: number;
  paymentDomicile: {
    accountNumber: string;
    accountType: string;
    ispb: string;
    agency: string;
    ownerDocument: string;
    ownerName: string;
    compe: string;
  };
}

enum PaymentInformationKindType {
  OWNERSHIP_CHANGE = '1',
  ONUS = '2',
  FIDUCIARY_CESSION = '3',
  ONUS_OTHERS = '4',
  JUDICIAL_BLOCK = '5',
  LIQUIDATION = '6',
  BANK_DOMICILE = '7',
  CESSION_PROMISE = '8'
}

export interface GetDetailedScheduleFiltersType {
  fromDate: string;
  toDate: string;
  paymentArrangement: string;
  online: boolean;
}

export interface GetDetailedScheduleResponseType
  extends Array<GetDetailedScheduleItemResponseType> {}

export interface GetDetailedScheduleItemResponseType {
  merchantName: string;
  merchantCnpj: string;
  id: string;
  liquidationDate: Date;
  register: string;
  acquirer: string;
  paymentArrangement: string;
  totalAmount: number;
  freeAmount: number;
  blockedAmount: number;
  status: string;
  blocks: GetDetailedBlockedSchedulesDtoType;
  formattedBlockedAmount: string;
  formattedFreeAmount: string;
  formattedTotalAmount: string;
  formattedLiquidationDate: string;
}

export interface GetDetailedScheduleDtoResponseType {
  schedule?: {
    merchantId: string;
    freeSchedule: GetDetailedFreeSchedulesDtoType;
    blockedSchedule: GetDetailedBlockedSchedulesDtoType;
  };
}

interface GetDetailedFreeSchedulesDtoType
  extends Array<GetDetailedFreeScheduleDtoType> {}

export interface GetDetailedFreeScheduleDtoType
  extends BaseDetailedScheduleDtoType {
  liquidated: boolean;
  blocks: GetDetailedBlockedSchedulesDtoType;
}

interface GetDetailedBlockedSchedulesDtoType
  extends Array<GetDetailedBlockedScheduleDtoType> {}

export interface GetDetailedBlockedScheduleDtoType
  extends BaseDetailedScheduleDtoType {
  oneratedAmount: number;
  paymentInformationKind: PaymentInformationKindType;
}
