import { IconButton, styled } from '@mui/material';

export const Styled = {
  Button: styled(IconButton)(
    ({
      theme: {
        palette: {
          primary: { main, dark }
        }
      }
    }) => ({
      color: main,

      '&:hover': {
        color: dark
      }
    })
  )
};
