import { Card, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo } from 'src/components/logo';

export const Styled = {
  Title: styled(Typography)(({ theme: { spacing, typography } }) => ({
    paddingBottom: spacing(2),
    fontSize: typography.pxToRem(30)
  })),

  Subtitle: styled(Typography)(
    ({ theme: { spacing, palette, typography } }) => ({
      fontSize: typography.pxToRem(16),
      color: palette.secondary.dark,
      paddingBottom: spacing(4)
    })
  ),

  LogoZak: styled(Logo)(({ theme: { spacing, breakpoints } }) => ({
    position: 'absolute',
    top: spacing(3),
    left: spacing(2),
    zIndex: 2,
    width: '100px',

    [`${breakpoints.up('md')}`]: {
      width: '140px'
    }
  })),

  Container: styled(Container)(() => {
    const beforeSize = '100%';
    const beforePos = 0;

    return {
      position: 'relative',
      minHeight: '100dvh',
      minWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '&:before': {
        position: 'absolute',
        top: beforePos,
        left: beforePos,
        width: beforeSize,
        height: beforeSize,
        content: '" "',
        zIndex: 1,
        background: "url('/images/login-bg.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'brightness(0.3) grayscale(0.2)'
      }
    };
  }),

  Content: styled(Card)(({ theme: { spacing, breakpoints } }) => ({
    position: 'relative',
    padding: spacing(2),
    maxWidth: '490px',
    zIndex: 2,
    marginTop: spacing(9),

    [`${breakpoints.up('md')}`]: {
      marginTop: 0
    }
  }))
};
