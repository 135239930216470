import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { withStores } from 'src/stores';
import { ThemeContainerProps } from './types';

const ThemeContainer = withStores<ThemeContainerProps>(
  ['LayoutStore'],
  ({ LayoutStore, children }: Required<ThemeContainerProps>) => {
    const { theme } = LayoutStore;

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    );
  }
);

export default ThemeContainer;
