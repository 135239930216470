import { ExitToAppTwoTone, ExpandMoreTwoTone } from '@mui/icons-material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Divider, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { withStores } from 'src/stores';
import { NavTabs } from './navTabs';
import { Styled } from './styles';
import { HeaderProps } from './types';
import { UserCard } from './userCard';

export const Header = withStores<HeaderProps>(
  ['LayoutStore', 'UserStore'],
  ({ LayoutStore, UserStore, routeId }: Required<HeaderProps>) => {
    const { sidebarVisibility, toggleSidebar } = LayoutStore;
    const [popoverVisibility, setPopoverVisibility] = useState(false);
    const userPopoverRef = useRef<HTMLButtonElement>(null);

    const togglePopover = () => setPopoverVisibility(!popoverVisibility);

    const handleLogout = async () => UserStore.handleLogout();

    return (
      <Styled.Container>
        <Styled.ButtonMenu onClick={toggleSidebar}>
          {!sidebarVisibility ? (
            <MenuTwoToneIcon fontSize="small" />
          ) : (
            <CloseTwoToneIcon fontSize="small" />
          )}
        </Styled.ButtonMenu>
        <NavTabs routeId={routeId} />
        <Styled.UserCardButton
          color="secondary"
          ref={userPopoverRef}
          onClick={togglePopover}
        >
          <UserCard />
          <ExpandMoreTwoTone />
        </Styled.UserCardButton>
        <Popover
          onClose={togglePopover}
          open={popoverVisibility}
          anchorEl={userPopoverRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Styled.PopoverUserCard>
            <UserCard />
          </Styled.PopoverUserCard>
          <Divider />
          <Styled.ButtonExit onClick={handleLogout}>
            <ExitToAppTwoTone />
            Sair
          </Styled.ButtonExit>
        </Popover>
      </Styled.Container>
    );
  }
);
