import { format } from 'date-fns';
import { action, makeAutoObservable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import { formatDateToStartOfDay, getMerchantById } from 'src/helpers/functions';
import { formatContractStatus } from 'src/helpers/masks';
import { validateOnlyNumber } from 'src/helpers/validators';
import {
  chargeDashService,
  GetChargeDashListFiltersType
} from 'src/services/chargeDash';
import {
  contractService,
  GetContractByIdGuaranteesType
} from 'src/services/contract';
import {
  detailedScheduleService,
  GetDetailedScheduleFiltersType
} from 'src/services/detailedSchedule';
import { GlobalStore } from '../global';
import { LayoutStore } from '../layout';
import { MerchantStore } from '../merchant';
import {
  ContractDetailsModalGuaranteesType,
  ContractDetailsModalType,
  ContractsFiltersType,
  ContractsType
} from './types';

export default class ContractsStore {
  layoutStore: LayoutStore;
  merchantStore: MerchantStore;

  contracts: ContractsType;
  filters: ContractsFiltersType;

  contractDetailsModal: ContractDetailsModalType;

  statusOptions: AutoCompleteOptionsType;
  externalIdOptions: AutoCompleteOptionsType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.layoutStore = globalStore.LayoutStore;
    this.merchantStore = globalStore.MerchantStore;

    this.filters = {
      externalId: null,
      dueDate: null,
      createdAt: null,
      status: null
    };

    this.contracts = [];

    this.contractDetailsModal = null;

    this.statusOptions = [
      'CREATED',
      'PARTIALLY_PAID',
      'PAID',
      'PARTIALLY_CANCELLED',
      'CANCELLED',
      'ERROR'
    ].map((item) => ({ id: item, label: formatContractStatus(item) }));
    this.externalIdOptions = [];
  }

  @action.bound
  setContracts = (contracts: ContractsType) => {
    this.contracts = contracts;
  };

  @action.bound
  setFiltersProps = (key: keyof ContractsFiltersType, value: any) => {
    this.filters[key] = value;
  };

  @action.bound
  setExternalIdOptions = (
    externalIdOptions: ContractsStore['externalIdOptions']
  ) => {
    this.externalIdOptions = externalIdOptions;
  };

  handleContracts = async () => {
    try {
      this.layoutStore.showBackdrop();

      const filters: GetChargeDashListFiltersType = {
        ...(this.filters.externalId?.id && {
          externalId: this.filters.externalId.id
        }),
        ...(this.filters.dueDate && { dueDate: this.filters.dueDate }),
        ...(this.filters.createdAt && { createdAt: this.filters.createdAt }),
        ...(this.filters.status?.id && { status: this.filters.status.id }),
        ...(this.merchantStore.selectedMerchant?.id && {
          merchantId: this.merchantStore.selectedMerchant.id
        })
      };

      const { data } = await chargeDashService.getChargeDashList(
        filters,
        this.merchantStore.merchants
      );
      const { charges } = data;

      this.setExternalIdOptions(
        charges.map(({ id }) => ({
          label: id,
          id
        }))
      );

      this.setContracts(charges);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        'Não foi possível recuperar os contratos. Por favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  openContractDetailsModal = async (contractId: string) => {
    try {
      this.layoutStore.showBackdrop();

      const {
        data: {
          charge: {
            id,
            paymentDomicile,
            amount,
            dueDate,
            debitBalance,
            fee,
            merchantId,
            constitutedAmount,
            createdAt,
            updatedAt,
            guarantees,
            status,
            signatureDate,
            guaranteeHistory
          }
        }
      } = await contractService.getContractById(contractId);

      const merchant = getMerchantById(
        this.merchantStore.merchants,
        merchantId
      );

      const handleGuarantees = (guarantees: GetContractByIdGuaranteesType) =>
        guarantees.map(
          ({
            acquirerSubacquirer,
            constitutedAmount,
            divisionRule,
            liquidationDate,
            id,
            oneratedAmount,
            paymentsArrangement
          }) => ({
            acquirerSubacquirer,
            constitutedAmount,
            divisionRule,
            liquidationDate: formatDateToStartOfDay(liquidationDate),
            id,
            oneratedAmount,
            paymentsArrangement,
            isNew: false
          })
        );

      this.setContractDetailsModal({
        contract: {
          amount,
          constitutedAmount,
          createdAt: new Date(createdAt),
          debitBalance,
          dueDate: new Date(dueDate),
          fee,
          guarantees: handleGuarantees(guarantees),
          guaranteesHistory: handleGuarantees(guaranteeHistory),
          id,
          merchant,
          paymentDomicile: {
            accountNumber: paymentDomicile?.accountNumber
              ? paymentDomicile.accountNumber
              : '',
            accountType: paymentDomicile?.accountType
              ? paymentDomicile.accountType
              : 'CC',
            agency: paymentDomicile?.agency ?? '',
            compe: paymentDomicile?.compe ?? '341',
            ispb: paymentDomicile?.ispb ? paymentDomicile.ispb : '60701190',
            ownerDocument: paymentDomicile?.ownerDocument
              ? paymentDomicile.ownerDocument
              : '05508946000187',
            ownerName: paymentDomicile?.ownerName
              ? paymentDomicile.ownerName
              : 'STARPAY MDRSOLUTION SERVICOS LTDA.'
          },
          signatureDate,
          status,
          updatedAt: new Date(updatedAt)
        }
      });
    } catch {
      enqueueSnackbar(
        'Não foi possível recuperar as informações do contrato. Por favor, tente novamente mais tarde.',
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };

  closeContractDetailsModal = () => {
    this.setContractDetailsModal(null);
  };

  @action.bound
  setContractDetailsModal = (modal: ContractDetailsModalType) => {
    this.contractDetailsModal = modal;
  };

  // syncContract = async (id: string) => {
  //   try {
  //     this.layoutStore.showBackdrop();

  //     await chargeToolsService.getChargeToolsSyncContract(id);

  //     this.handleContracts();
  //     enqueueSnackbar('Contrato sincronizado com sucesso!', {
  //       variant: 'success'
  //     });
  //   } catch (err) {
  //     enqueueSnackbar(
  //       'Não foi possível sincronizar o status com a registradora. Por favor, tente novamente mais tarde.',
  //       { variant: 'error' }
  //     );
  //   } finally {
  //     this.layoutStore.hideBackdrop();
  //   }
  // };

  handleSchedule = async (filters: GetDetailedScheduleFiltersType) => {
    try {
      if (!this.contractDetailsModal) {
        throw new Error('contractDetailsModal is undefined or null');
      }

      const { contract } = this.contractDetailsModal;

      if (!contract.merchant?.id) {
        throw new Error('merchant is undefined or null');
      }

      const { merchant } = contract;

      return await detailedScheduleService.getDetailedSchedule(
        merchant,
        filters
      );
    } catch (e) {
      return [];
    }
  };

  handleUpdateContract = async ({
    accountNumber,
    agency,
    guarantees
  }: {
    accountNumber: string;
    agency: string;
    guarantees: ContractDetailsModalGuaranteesType;
  }) => {
    try {
      if (!this.contractDetailsModal) {
        throw new Error('contractDetailsModal is undefined or null');
      }

      this.layoutStore.showBackdrop();

      const { contract } = this.contractDetailsModal;
      const { amount, id, paymentDomicile, fee } = contract;
      const { accountType, compe, ispb, ownerDocument, ownerName } =
        paymentDomicile;

      await contractService.putContract({
        id,
        amount,
        fee,
        guarantees: guarantees.map(
          ({
            acquirerSubacquirer,
            constitutedAmount,
            liquidationDate,
            divisionRule,
            id,
            isNew,
            oneratedAmount,
            paymentsArrangement
          }) => ({
            acquirerSubacquirer: validateOnlyNumber(acquirerSubacquirer),
            constitutedAmount,
            liquidationDate: format(liquidationDate, 'yyyy-MM-dd'),
            divisionRule,
            ...(!isNew && { id }),
            oneratedAmount,
            paymentsArrangement
          })
        ),
        paymentDomicile: {
          accountNumber,
          accountType,
          agency,
          compe,
          ispb,
          ownerDocument,
          ownerName
        }
      });

      await this.handleContracts();
      this.closeContractDetailsModal();

      enqueueSnackbar('Contrato atualizado com sucesso!', {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(
        `Não foi possível atualizar o contrato. Por favor, tente novamente mais tarde. ${error}`,
        { variant: 'error' }
      );
    } finally {
      this.layoutStore.hideBackdrop();
    }
  };
}
