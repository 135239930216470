interface AcquirersSubacquirersType
  extends Array<{ document: string; name: string }> {}

const acquirers_subacquirers: AcquirersSubacquirersType = [
  { document: '10440482000154', name: 'Get Net' },
  { document: '14625224000101', name: 'Stelo' },
  { document: '01027058000191', name: 'Cielo' },
  { document: '14380200000121', name: 'iFood' },
  { document: '08561701014323', name: 'PagSeguro' },
  { document: '01181521000155', name: 'Sicredi' },
  { document: '01425787000104', name: 'RedeCard' },
  { document: '16501555000157', name: 'Stone' },
  { document: '04962772000165', name: 'FISERV' },
  { document: '26900161000125', name: 'Rappi' },
  { document: '31633039000170', name: 'CredPag' },
  { document: '29150228000140', name: 'AGL' },
  { document: '23757841000143', name: 'Frogpay' },
  { document: '19468242000132', name: 'Zoop' },
  { document: '58160789000128', name: 'Safra' },
  { document: '22177858000169', name: 'Granito' },
  { document: '04088208000165', name: 'Sem Parar' },
  { document: '02038232000245', name: 'Sicoob' },
  { document: '18727053000174', name: 'Pagar.me' },
  { document: '16668076000120', name: 'SumUp' }
];

export default acquirers_subacquirers;
