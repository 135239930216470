import { handleQueryParams } from 'src/helpers/functions';
import requester from '../requester';
import { GetOrdersDtoResponseType, GetOrdersFiltersType } from './types';

const isAuthenticated = true;
const url = 'orders';

export const ordersService = {
  getOrders: async (filters: GetOrdersFiltersType) => {
    try {
      const queryParams = handleQueryParams(filters);

      console.log(queryParams);

      const response = await requester<GetOrdersDtoResponseType>({
        url: `${url}${queryParams}`,
        method: 'GET',
        isAuthenticated
      });

      const { data } = response;

      if (!data) {
        throw new Error('data is undefined');
      }

      // return { data: orders_mock }; // Mock
      return response;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  }
};
