import { ConfigStore } from '../config';
import { ContractsStore } from '../contracts';
import { GuaranteesStore } from '../guarantees';
import { LayoutStore } from '../layout';
import { LimitStore } from '../limit/';
import { MerchantStore } from '../merchant';
import { OrdersStore } from '../orders';
import { RouterStore } from '../router';
import { ScheduleStore } from '../schedule';
import { SuppliersStore } from '../suppliers';
import { UserStore } from '../user';

export default class GlobalStore {
  RouterStore: RouterStore;
  LayoutStore: LayoutStore;
  UserStore: UserStore;
  MerchantStore: MerchantStore;
  ScheduleStore: ScheduleStore;
  ContractsStore: ContractsStore;
  GuaranteesStore: GuaranteesStore;
  ConfigStore: ConfigStore;
  SuppliersStore: SuppliersStore;
  OrdersStore: OrdersStore;
  LimitStore: LimitStore;

  constructor() {
    this.LayoutStore = new LayoutStore(this);
    this.UserStore = new UserStore(this);
    this.RouterStore = new RouterStore(this);
    this.MerchantStore = new MerchantStore(this);
    this.ScheduleStore = new ScheduleStore(this);
    this.ContractsStore = new ContractsStore(this);
    this.GuaranteesStore = new GuaranteesStore(this);
    this.ConfigStore = new ConfigStore(this);
    this.SuppliersStore = new SuppliersStore(this);
    this.OrdersStore = new OrdersStore(this);
    this.LimitStore = new LimitStore(this);
  }

  async init() {
    if (this.UserStore.userSession) {
      await this.MerchantStore.init();
    }
  }
}
