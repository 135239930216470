import {
  AttachMoneyTwoTone,
  CalendarMonthTwoTone,
  CreditCardTwoTone,
  DashboardTwoTone,
  EngineeringTwoTone,
  FolderSharedTwoTone,
  GavelTwoTone,
  SearchTwoTone,
  StorefrontTwoTone,
  TravelExploreTwoTone
} from '@mui/icons-material';
import { makeAutoObservable, reaction } from 'mobx';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { staticRoutes } from 'src/helpers/navigationRoutes';
import { v4 } from 'uuid';
import { GlobalStore } from '../global';
import { UserStore } from '../user';
import {
  ActiveRoutesType,
  ModulesType,
  PrivateRoutesType,
  PrivateRouteType,
  PublicRoutesType
} from './types';

const Login = lazy(() => import('src/modules/login/layout'));
const Home = lazy(() => import('src/modules/home/layout'));
const Merchants = lazy(() => import('src/modules/merchants/layout'));
const Schedule = lazy(() => import('src/modules/schedule/layout'));
const LimitQuery = lazy(() => import('src/modules/limit/query/layout'));
const LimitSimulation = lazy(
  () => import('src/modules/limit/simulation/layout')
);
const LimitBaseQuery = lazy(() => import('src/modules/limit/baseQuery/layout'));
const Contracts = lazy(() => import('src/modules/contracts/layout'));
const Guarantees = lazy(() => import('src/modules/guarantees/layout'));
const Users = lazy(() => import('src/modules/users/layout'));
const PaymentsArrangements = lazy(
  () => import('src/modules/paymentsArrangements/layout')
);
const Orders = lazy(() => import('src/modules/orders/layout'));
const ModuleRoutesPage = lazy(
  () => import('src/components/moduleRoutesPage/layout')
);
const StatusPage = lazy(() => import('src/components/statusPage/layout'));

export default class RouterStore {
  userStore: UserStore;

  modules: ModulesType;

  activeRoutes: ActiveRoutesType;

  homeRoute: PrivateRouteType;
  privateRoutes: PrivateRoutesType;
  publicRoutes: PublicRoutesType;

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.userStore = globalStore.UserStore;

    const { contracts, limit, empty, settings } = staticRoutes;

    this.modules = {
      limit: { name: 'Limites', path: limit },
      contracts: { name: 'Contratos', path: contracts },
      settings: { name: 'Configurações', path: settings }
    };

    this.activeRoutes = null;

    this.homeRoute = {
      title: 'Dashboard',
      path: empty,
      children: <Home />,
      subtitle:
        'Explore dados de pedidos e clientes com facilidade. Utilize os filtros por data e fornecedor, exibindo gráficos informativos para uma análise visual.',
      icon: <DashboardTwoTone />,
      isMenu: true
    };
    this.publicRoutes = [];
    this.privateRoutes = [];

    this.handleRoutes(this.userStore.userSession);

    reaction(
      () => this.userStore.userSession,
      () => {
        this.handleRoutes(this.userStore.userSession);
      }
    );
  }

  handleRoutes = (userSession: UserStore['userSession']) => {
    const {
      all,
      empty,
      root,
      baseQuery,
      comingSoon,
      guarantees,
      internalServerError,
      list,
      maintenance,
      merchants,
      notAllowed,
      notFound,
      orders,
      paymentsArrangements,
      query,
      schedule,
      simulation,
      users
    } = staticRoutes;

    const handlePrivateRoutesProps = (routes: PrivateRoutesType) =>
      routes.map(
        ({
          hasViewPermission = true,
          children,
          module,
          path,
          routeId = v4(),
          ...item
        }) =>
          ({
            ...item,
            module,
            hasViewPermission,
            routeId,
            children: hasViewPermission ? (
              children
            ) : (
              <Navigate to={`/${notAllowed}`} replace />
            ),
            path:
              module !== undefined
                ? `${this.modules[module].path}${path ? `/${path}` : ''}`
                : path
          } as PrivateRouteType)
      );

    const publicRoutes: PublicRoutesType = [
      {
        children: <Login />,
        path: empty,
        title: 'Zak Register',
        routeTitle: 'Login',
        subtitle:
          'Plataforma destinada ao controle interno de cobranças por  meio de recebíveis de cartão de crédito.'
      },
      {
        path: all,
        children: <Navigate to={root} replace />
      }
    ];

    const privateRoutes: PrivateRoutesType = [
      this.homeRoute,
      {
        title: 'Acesso negado',
        subtitle: (
          <>
            Desculpe, mas você não possui permissão para acessar essa página.
            <br />
            Por favor, consulte um administrador do sistema para revisão do seu
            nível de acesso.
          </>
        ),
        path: notAllowed,
        children: <StatusPage image="/images/405.svg" />,
        vPosition: 'center'
      },
      {
        title: 'Página não encontrada',
        subtitle: (
          <>
            Desculpe, mas não conseguimos encontrar a página que você está
            procurando.
            <br />
            Por favor, reveja o endereço informado ou volte para nossa home.
          </>
        ),
        path: notFound,
        children: <StatusPage image="/images/404.svg" />,
        vPosition: 'center'
      },
      {
        title: 'Aconteceu algum erro',
        subtitle: (
          <>
            O servidor encontrou um erro interno e não conseguiu completar sua
            requisição.
            <br />
            Por favor, tente novamente mais tarde ou volte para nossa home.
          </>
        ),
        path: internalServerError,
        children: <StatusPage image="/images/500.svg" />,
        vPosition: 'center'
      },
      {
        title: 'Página em Manutenção',
        subtitle: (
          <>
            Já estamos trabalhando o mais rápido possível para voltar a
            normalidade.
            <br />
            Por favor, tente novamente mais tarde ou volte para nossa home.
          </>
        ),
        path: maintenance,
        children: <StatusPage image="/images/maintenance.svg" />,
        vPosition: 'center'
      },
      {
        title: 'Em breve!',
        subtitle: (
          <>
            Estamos trabalhando para implementar as últimas funcionalidades para
            o lançamento.
            <br />
            Por favor, tente novamente mais tarde ou volte para nossa home.
          </>
        ),
        path: comingSoon,
        children: <StatusPage image="/images/coming-soon.svg" />,
        vPosition: 'center'
      },
      {
        path: merchants,
        children: <Merchants />,
        title: 'Estabelecimentos',
        hasViewPermission: userSession?.permissions?.merchants.view,
        icon: <StorefrontTwoTone />,
        isMenu: true,
        breadcrumbs: true
      },
      {
        path: schedule,
        children: <Schedule />,
        title: 'Agenda de Recebíveis',
        hasViewPermission: userSession?.permissions?.schedule,
        icon: <CalendarMonthTwoTone />,
        isMenu: true,
        breadcrumbs: true
      },
      // {
      //   path: orders,
      //   children: <Orders />,
      //   title: 'Pedidos',
      //   hasViewPermission: userSession?.permissions?.orders,
      //   icon: <SellTwoTone />,
      //   isMenu: true,
      //   breadcrumbs: true
      // },
      {
        module: 'limit',
        path: empty,
        title: 'Limites',
        hasViewPermission:
          userSession?.permissions?.limit.query ||
          userSession?.permissions?.limit.baseQuery ||
          userSession?.permissions?.limit.simulation,
        children: <ModuleRoutesPage module="limit" />,
        breadcrumbs: true
      },
      {
        module: 'limit',
        path: query,
        title: 'Consulta Estabelecimento',
        hasViewPermission: userSession?.permissions?.limit.query,
        icon: <SearchTwoTone />,
        children: <LimitQuery />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        module: 'limit',
        path: baseQuery,
        title: 'Consulta Base',
        hasViewPermission: userSession?.permissions?.limit.baseQuery,
        icon: <TravelExploreTwoTone />,
        children: <LimitBaseQuery />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        module: 'limit',
        path: simulation,
        title: 'Simular Limite',
        hasViewPermission: userSession?.permissions?.limit.simulation,
        icon: <EngineeringTwoTone />,
        children: <LimitSimulation />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        module: 'contracts',
        path: empty,
        hasViewPermission:
          userSession?.permissions?.contracts.list.view ||
          userSession?.permissions?.contracts.guarantees.view,
        title: 'Contratos',
        children: <ModuleRoutesPage module="contracts" />,
        breadcrumbs: true
      },
      {
        module: 'contracts',
        title: 'Contratos',
        subtitle:
          'Cadastro e gerenciamento de contratos (travas) emitidas por efeitos de cobrança de fornecedores, cobrança do SaaS Zak e outros.',
        icon: <GavelTwoTone />,
        path: list,
        hasViewPermission: userSession?.permissions?.contracts.list.view,
        children: <Contracts />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        module: 'contracts',
        title: 'Garantias',
        subtitle:
          'Garantias são as agendas de valores que a Zak tem a receber dos Estabelecimentos Comerciais',
        icon: <AttachMoneyTwoTone />,
        path: guarantees,
        hasViewPermission: userSession?.permissions?.contracts.guarantees.view,
        children: <Guarantees />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        module: 'settings',
        path: empty,
        hasViewPermission:
          userSession?.permissions?.settings.paymentsArrangements.view ||
          userSession?.permissions?.settings.users.view,
        title: 'Configurações',
        children: <ModuleRoutesPage module="settings" />,
        breadcrumbs: true
      },
      {
        module: 'settings',
        title: 'Arranjos de Pagamentos',
        subtitle:
          'Arranjos que serão considerados no cálculo do limite e no opt-in dos clientes',
        icon: <CreditCardTwoTone />,
        path: paymentsArrangements,
        hasViewPermission:
          userSession?.permissions?.settings.paymentsArrangements.view,
        children: <PaymentsArrangements />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        module: 'settings',
        title: 'Usuários do Sistema',
        subtitle: 'Controle e gerenciamento de accesso ao sistema.',
        icon: <FolderSharedTwoTone />,
        path: users,
        hasViewPermission: userSession?.permissions?.settings.users.view,
        children: <Users />,
        isMenu: true,
        isTab: true,
        breadcrumbs: true
      },
      {
        path: all,
        children: <Navigate to={notFound} replace />
      }
    ];

    this.publicRoutes = publicRoutes;
    this.privateRoutes = handlePrivateRoutesProps(privateRoutes);

    this.activeRoutes = !!userSession ? 'private' : 'public';
  };
}
