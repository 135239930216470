import { Divider, List, ListItem, ListSubheader, styled } from '@mui/material';
import { Logo } from 'src/components/logo/layout';
import { ScrollContainer } from 'src/components/scrollContainer';
import { StyledListSubheaderProps, StyledSubMenuContainerProps } from './types';

export const Styled = {
  LogoZak: styled(Logo)(({ theme: { spacing } }) => ({
    margin: `${spacing(3)} ${spacing(2)}`
  })),

  Line: styled(Divider)(({ theme: { spacing, palette } }) => ({
    margin: `0 ${spacing(2)}`,
    background: palette.alpha.white[10]
  })),

  Container: styled(ScrollContainer)(
    ({ theme: { spacing, typography, palette } }) => ({
      padding: `${spacing(4)} ${spacing(1)} ${spacing(2)}`,

      '.MuiList-root': {
        padding: 0
      },

      '.MuiListSubheader-root': {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: typography.pxToRem(12),
        color: palette.alpha.white[50],
        lineHeight: 1.4,
        paddingTop: spacing(2),
        paddingRight: spacing(1),
        paddingBottom: spacing(1.75),
        paddingLeft: spacing(1)
      }
    })
  ),

  ListSubheader: styled(({ disabled, ...props }: StyledListSubheaderProps) => (
    <ListSubheader {...props} />
  ))(({ disabled }) => ({
    ...(disabled && { opacity: 0.1 })
  })),

  SubMenuContainer: styled(
    ({ multipleMenuItems, ...props }: StyledSubMenuContainerProps) => (
      <List {...props} />
    )
  )(({ theme, multipleMenuItems }) => ({
    paddingLeft: multipleMenuItems
      ? `${theme.spacing(2)} !important`
      : '0 !important'
  })),

  SubMenuItem: styled(ListItem)(
    ({ theme: { spacing, transitions, palette, typography } }) => {
      const iconColorStyle = { color: palette.common.white };

      const activeHoverStyle = {
        backgroundColor: palette.primary.main,
        color: palette.common.white,

        '.MuiButton-startIcon': iconColorStyle,
        '.MuiButton-endIcon': iconColorStyle
      };

      const buttonStyle = {
        transition: transitions.create(['color'])
      };

      return {
        padding: `${spacing(0.1)} 0`,

        '.MuiButton-root': {
          display: 'flex',
          color: palette.alpha.white[70],
          backgroundColor: 'transparent',
          width: '100%',
          justifyContent: 'flex-start',
          padding: spacing(1, 2),

          '.MuiButton-startIcon': buttonStyle,

          '.MuiButton-endIcon': buttonStyle,

          '.MuiSvgIcon-root': {
            fontSize: 'inherit',
            transition: 'none'
          },

          '&.active': activeHoverStyle,
          '&:hover': activeHoverStyle
        },

        '.MuiButton-startIcon': {
          color: palette.alpha.white[30],
          fontSize: typography.pxToRem(20),
          marginRight: spacing(1)
        },

        '.MuiButton-endIcon': {
          color: palette.alpha.white[50],
          marginLeft: 'auto',
          opacity: 0.8,
          fontSize: typography.pxToRem(20)
        }
      };
    }
  )
};
