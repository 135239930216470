import { CardContent, Grid, GridProps } from '@mui/material';
import { RouteHelmet } from 'src/components/router/routeHelmet';
import { RouteLoader } from 'src/components/router/routeLoader';
import { Styled } from './styles';
import { PublicRouteProps } from './types';

export const PublicRoute = ({
  children,
  routeHelmetProps,
  routeTitle,
  subtitle,
  title
}: PublicRouteProps) => {
  const gridItemProps: GridProps = {
    item: true,
    xs: 12
  };

  return (
    <Styled.Container>
      <RouteHelmet {...routeHelmetProps} title={routeTitle ?? title ?? ''} />
      <Styled.LogoZak variant="white" />
      <Styled.Content>
        <CardContent>
          <Grid spacing={3} container>
            <Grid {...gridItemProps} item>
              <Styled.Title variant="h1">{title}</Styled.Title>
              {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
            </Grid>
            <Grid {...gridItemProps} item>
              <RouteLoader>{children}</RouteLoader>
            </Grid>
          </Grid>
        </CardContent>
      </Styled.Content>
    </Styled.Container>
  );
};
