import { Box } from '@mui/material';
import { Styled } from './styles';
import { PermissionTooltipProps } from './types';

export const PermissionTooltip = ({
  followCursor = true,
  disabled,
  title,
  variant = 'dark',
  children,
  ...props
}: PermissionTooltipProps) =>
  disabled ? (
    <Styled.Tooltip
      {...props}
      variant={variant}
      title={title ?? 'Você não tem permissão para isso'}
      followCursor={followCursor}
    >
      <Box>{children}</Box>
    </Styled.Tooltip>
  ) : (
    children
  );
