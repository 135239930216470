import { format } from 'date-fns';
import {
  formatDateToStartOfDay,
  handleQueryParams
} from 'src/helpers/functions';
import {
  formatAcquirerSubacquirer,
  formatCurrency,
  formatRegister
} from 'src/helpers/masks';
import { MerchantItemType } from 'src/stores/merchant';
import requester from '../requester';
import {
  GetDetailedBlockedScheduleDtoType,
  GetDetailedFreeScheduleDtoType,
  GetDetailedScheduleDtoResponseType,
  GetDetailedScheduleFiltersType,
  GetDetailedScheduleItemResponseType,
  GetDetailedScheduleResponseType
} from './types';

const url = 'detailed-schedule';
const isAuthenticated = true;

export const detailedScheduleService = {
  getDetailedSchedule: async (
    merchant: MerchantItemType,
    filters: GetDetailedScheduleFiltersType
  ): Promise<GetDetailedScheduleResponseType> => {
    try {
      const queryParams = handleQueryParams({
        ...filters,
        merchantId: merchant.id
      });

      const response = await requester<GetDetailedScheduleDtoResponseType>({
        url: `${url}${queryParams}`,
        method: 'GET',
        isAuthenticated
      });

      if (!response.data || !response?.data?.schedule) {
        throw new Error('There is no data for this response.');
      }

      const formatAmount = (amount: number) =>
        formatCurrency(amount, { removeSuffix: true });

      const handleBaseScheduleProps = (
        id: number,
        item: GetDetailedFreeScheduleDtoType | GetDetailedBlockedScheduleDtoType
      ) => {
        const totalAmount = item.amount;
        const liquidationDate = formatDateToStartOfDay(item.liquidationDate);

        return {
          id: id.toString(),
          merchantName: merchant?.name ?? '',
          merchantCnpj: merchant?.cnpj ?? '',
          acquirer: formatAcquirerSubacquirer(item.acquirerSubacquirerCNPJ),
          register: formatRegister(item.registerEntity),
          liquidationDate,
          paymentArrangement: item.paymentArrangement,
          totalAmount,
          formattedTotalAmount: formatAmount(totalAmount),
          formattedLiquidationDate: format(liquidationDate, 'dd/MM/yyyy')
        };
      };

      const { freeSchedule, blockedSchedule } = response.data.schedule;
      let schedule: GetDetailedScheduleResponseType = [];
      let id = 0;

      freeSchedule.forEach((item: GetDetailedFreeScheduleDtoType) => {
        let blockedAmount = 0;

        item.blocks?.forEach((block: GetDetailedBlockedScheduleDtoType) => {
          blockedAmount += block.oneratedAmount;
        });
        id++;
        let freeAmount = item.amount;

        schedule.push({
          ...handleBaseScheduleProps(id, item),
          blockedAmount,
          freeAmount,
          status: blockedAmount > 0 ? 'Bloqueado' : 'Livre',
          blocks: item.blocks,
          formattedBlockedAmount: formatAmount(blockedAmount),
          formattedFreeAmount: formatAmount(freeAmount)
        });
      });

      blockedSchedule.forEach((item: GetDetailedBlockedScheduleDtoType) => {
        let existent = schedule.find(
          (e) =>
            e.liquidationDate.toISOString().slice(0, 10) ===
              new Date(item.liquidationDate).toISOString().slice(0, 10) &&
            e.register === item.acquirerSubacquirerCNPJ &&
            e.acquirer === item.acquirerSubacquirerCNPJ &&
            e.paymentArrangement === item.paymentArrangement
        );
        if (existent) {
          existent.blocks.push(item);
          existent.blockedAmount += item.oneratedAmount;
          existent.status = existent.blockedAmount > 0 ? 'Bloqueado' : 'Livre';
        } else {
          id++;
          let blockedAmount = item.amount;
          let freeAmount = 0;

          schedule.push({
            ...handleBaseScheduleProps(id, item),
            blockedAmount,
            freeAmount,
            status: item.paymentInformationKind,
            blocks: [],
            formattedBlockedAmount: formatAmount(blockedAmount),
            formattedFreeAmount: formatAmount(freeAmount)
          });
        }
      });

      // Sort items by liquidation date, register, acquirer, payment arrangement,
      schedule = schedule.sort(
        (
          a: GetDetailedScheduleItemResponseType,
          b: GetDetailedScheduleItemResponseType
        ) => {
          if (a.liquidationDate < b.liquidationDate) {
            return -1;
          }
          if (a.liquidationDate > b.liquidationDate) {
            return 1;
          }
          if (a.register < b.register) {
            return -1;
          }
          if (a.register > b.register) {
            return 1;
          }
          if (a.acquirer < b.acquirer) {
            return -1;
          }
          if (a.acquirer > b.acquirer) {
            return 1;
          }
          if (a.paymentArrangement < b.paymentArrangement) {
            return -1;
          }
          if (a.paymentArrangement > b.paymentArrangement) {
            return 1;
          }
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        }
      );

      return schedule;
    } catch (e) {
      const error = e as Error;

      console.error(error);
      throw new Error(error?.message);
    }
  }
};
