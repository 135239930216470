interface DynamicRoutesParamsType {
  id?: string;
}

const zakPayRootPath = `https://pay.${
  process.env.REACT_APP_STAGE === 'production' ? '' : 'development.'
}zak.app`;

export enum staticRoutes {
  all = '*',
  empty = '',
  root = '/',
  orders = 'orders',
  merchants = 'merchants',
  schedule = 'schedule',
  contracts = 'contracts',
  limit = 'limit',
  settings = 'settings',
  guarantees = 'guarantees',
  baseQuery = 'base-query',
  query = 'query',
  notFound = '404',
  notAllowed = '405',
  internalServerError = '500',
  list = 'list',
  simulation = 'simulation',
  maintenance = 'maintenance',
  comingSoon = 'coming-soon',
  paymentsArrangements = 'payments-arrangements',
  users = 'users'
}

export const dynamicRoutes = ({ id }: DynamicRoutesParamsType) => ({
  zakPayOrderPaymentId: `${zakPayRootPath}/pedido/${id ?? ''}/pagamento`
});

export const navigateToLogin = () => {
  window.location.href = staticRoutes.root;
};
